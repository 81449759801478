import formatToBrl from "utils/FormatToBrl";

const exchangeValues = (quoteValue, quote, coins, needCalculate) => {
    let decimalValue = quoteValue;
    if (typeof quoteValue === "string") {
        decimalValue = quoteValue.replace(/\D/g, "");
    }
    // Transforma o valor inserido em um número, aceitando somente dígitos

    // Verifica se o valor tem pelo menos 3 caracteres
    if (quoteValue.length >= 3) {
        // Transforma o valor em um formato de número decimal
        decimalValue = decimalValue.slice(0, -2) + "." + decimalValue.slice(-2);
    }
    decimalValue = parseFloat(decimalValue); // Converte para um número de ponto flutuante

    // Calcula o preço real considerando o spread
    const realPrice = quote.price * (1 + quote.spread);

    // Elementos HTML
    const nacionalValue = document.getElementById("nacionalValue");
    const internacionalValue = document.getElementById("internacionalValue");

    // // Manipulação dos valores baseado no elemento clicado (nacional/internacional)
    // if (transaction === "nacionalValue") {
    //     function calculate(fee) {
    //         const administrationFee = fee || 25;

    //         // Cálculos baseados na moeda nacional
    //         const realQuote =
    //             quote.way === "enviar"
    //                 ? decimalValue - administrationFee
    //                 : decimalValue + administrationFee;
    //         const iof = realQuote * quote.iof;
    //         const finalBrlQuote = quote.way === "enviar" ? realQuote - iof : realQuote + iof;
    //         const finalCoinQuote = finalBrlQuote / realPrice;

    //         // Atualização dos valores da cotação
    //         quote.spreadValue = parseFloat(
    //             (realQuote / quote.price - realQuote / realPrice).toFixed(2)
    //         );

    //         const totalTax = quote.spreadValue + administrationFee;
    //         const swift = 12 * coins?.find((coin) => coin.from === "USD").bid;

    //         // Recalcula as taxas
    //         if (totalTax < swift + 10) {
    //             calculate(swift + 10 - totalTax);
    //             quote.administrationFee = swift + 10 - totalTax;
    //         } else if (fee === undefined) {
    //             quote.administrationFee = administrationFee;
    //         }

    //         quote.iofValue = parseFloat(iof.toFixed(2));
    //         quote.brlQuote = parseFloat(decimalValue.toFixed(2));
    //         quote.coinQuote = parseFloat(finalCoinQuote.toFixed(2));
    //         quote.vet = parseFloat((decimalValue / finalCoinQuote).toFixed);

    //         // Altera o valor no formato de moeda no input nacional
    //         if (quoteValue.length >= 3) {
    //             const onlyNumbers = quoteValue.replace(/[.,]/g, "");
    //             const decimalValue = onlyNumbers.slice(0, -2) + "." + onlyNumbers.slice(-2);
    //             nacionalValue.value = formatToBrl(decimalValue);
    //         }

    //         // Insere no input internacional se o valor for maior que 149
    //         if (decimalValue > 149) {
    //             internacionalValue.value = formatToBrl(finalCoinQuote);
    //         } else {
    //             internacionalValue.value = "";
    //         }
    //     }
    //     calculate();
    // }

    // Manipulação dos valores baseado no elemento clicado (nacional/internacional)
    // if (transaction === "internacionalValue") {
    function calculate(fee) {
        const administrationFee = fee || 25;
        // Cálculos baseados na moeda internacional
        const initialQuote = decimalValue * realPrice;
        let realQuote = initialQuote + administrationFee;
        const iof = realQuote * quote.iof;
        const ir = realQuote * quote.ir;
        let finalBrlQuote = realQuote + iof + ir;

        quote.irValue = formatToBrl(ir);
        quote.iofValue = formatToBrl(iof);

        // Atualização dos valores da cotação
        quote.spreadValue = parseFloat(
            (realQuote / quote.price - realQuote / realPrice).toFixed(2)
        );

        const totalTax = quote.spreadValue + administrationFee;
        const swift = 12 * coins?.find((coin) => coin.from === "USD").bid;

        // Recalcula as taxas
        if (totalTax < swift + 10) {
            quote.administrationFee = parseFloat((swift + 10 - totalTax).toFixed(4));
            calculate(swift + 10 - totalTax);
        } else if (fee === undefined) {
            quote.administrationFee = administrationFee.toFixed(4);
        }

        finalBrlQuote = parseFloat(
            quote.price * decimalValue + quote.spreadValue + iof + ir + quote.administrationFee
        );

        quote.brlQuote = finalBrlQuote.toFixed(2);
        quote.coinQuote = parseFloat(decimalValue.toFixed(2));
        quote.vet = parseFloat((finalBrlQuote / decimalValue).toFixed(4));

        if (needCalculate === true) {
            // Altera o valor no formato de moeda no input internacional
            if (quoteValue.length >= 3) {
                const onlyNumbers = quoteValue.replace(/[.,]/g, "");
                const decimalValue = onlyNumbers.slice(0, -2) + "." + onlyNumbers.slice(-2);
                internacionalValue.value = formatToBrl(decimalValue);
            }

            // Insere no input nacional se o valor for maior que 149 dividido pelo vet
            if (decimalValue > 149 / quote.vet) {
                nacionalValue.value = formatToBrl(finalBrlQuote);
            } else {
                nacionalValue.value = "";
            }
        }
    }
    calculate();
    //}
};

export default exchangeValues;
