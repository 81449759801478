import logo from "assets/images/zebra-logo-horizontal-branco.png";
import facebookIcon from "assets/icons/facebook.svg";
import linkedinIcon from "assets/icons/linkedin.svg";
import instagramIcon from "assets/icons/instagram.svg";
import { Link } from "react-router-dom";

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="navigation-cols">
                    <div className="about">
                        <img src={logo} alt="Zebra Câmbio" />
                        <br />
                        <h6>EMPRESA E EQUIPE</h6>
                        <Link to="https://zebracambio.com.br/sobre-nos/" className="link">
                            Sobre nós
                        </Link>
                        <Link to="https://zebracambio.com.br/znews/" className="link">
                            ZNews
                        </Link>
                        <Link
                            to="https://zebracambio.com.br/wp-content/Demonstrativos%20Financeiros/PROSEFTUR%20CORRETORA%20DE%20C%C3%82MBIO%20LTDA.%20-%20DEZ22%20DF%20final.pdf"
                            className="link"
                        >
                            Demonstrações financeiras
                        </Link>
                        <br />
                        <div className="social-media">
                            <h6>REDES SOCIAIS</h6>
                            <div className="icons">
                                <Link
                                    to="https://www.linkedin.com/company/zebra-cambio"
                                    target="_blank"
                                >
                                    <img src={linkedinIcon} alt="Linkedin" />
                                </Link>
                                <Link to="https://www.instagram.com/zebracambio/" target="_blank">
                                    <img src={instagramIcon} alt="Instagram" />
                                </Link>
                                <Link to="https://www.facebook.com/zebracambio" target="_blank">
                                    <img src={facebookIcon} alt="Facebook" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="policy">
                        <h6>POLÍTICAS INSTITUCIONAIS</h6>
                        <Link
                            to="https://zebracambio.com.br/wp-content/Pol%C3%ADticas%20Institucionais/C%C3%93DIGO%20DE%20%C3%89TICA%20E%20CONDUTA.pdf"
                            className="link"
                        >
                            Código de ética e conduta
                        </Link>
                        <Link
                            to="https://zebracambio.com.br/politica-de-privacidade/"
                            className="link"
                        >
                            Política de privacidade
                        </Link>
                        <Link
                            to="https://zebracambio.com.br/wp-content/Pol%C3%ADticas%20Institucionais/POL%C3%8DTICA%20DE%20RESPONSABILIDADE%20SOCIAL%2C%20AMBIENTAL%20E%20CLIM%C3%81TICA%20(PRSAC)_v2.pdf"
                            className="link"
                        >
                            Política de responsabilidade socioambiental
                        </Link>
                        <Link
                            to="https://zebracambio.com.br/wp-content/Pol%C3%ADticas%20Institucionais/POLITICA%20PLD.FTP_V4_MAIO2023.pdf"
                            className="link"
                        >
                            Política de PLD.FT
                        </Link>
                        <Link
                            to="https://zebrawebdocuments.s3.sa-east-1.amazonaws.com/Termos%20de%20Uso%20-%20Zebra%20Web.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXNhLWVhc3QtMSJHMEUCICIzj%2BCIbi2S3cXng4bsQl1JJ81wOwszusy7rSeHoKbQAiEAhBkfTS7ml6ImGxd6ok435GLCvDKRRCUZ6NetNKKMg28q5AIIFhABGgwzMjI5NTA2MjQwMzIiDBp%2FawkmJhHbQ7HvcyrBAqEw9OK4R5n0tAYvJBj1xjozouP2iZAWghoii1nGLJvq7OMeUyezYTib7brjeAZWN%2FxuaRsAdeCxPj4wfM4I5tsV%2FDUKCEJi1bgKD5An0WScbwrfhBZiN9jPxAs3sLu8Rf1aoa2dA1VXbKqtjDSg8vHYQH0GCjHg%2BpFnxzfYZfNPYJ3hykO%2B7sIVYsyMOmEzCoBUpO%2BDhpJxgqvk1l6VWy2V%2FfabmM%2BM%2FlkYmMwMgqyBlxmrroc05gm7dZxczWZs2byyMMPLiXXWEfI29Zp5G0C6TC1l32EvOmDZTKGRyig3WI4x3utwZM2wIK6XFYcdJ0JKBBDJ%2Bs3podKZGALa9EvaJ7CKElyXGgKCvVIuWYTb%2BMYZcgAAQqzQR4yNocKieQgPKb3r6ZIWQjogGRnkm7Om3iVbWgGNjpWHjvqBuLYpPzDYoaerBjqzAmJJ%2BdP9q11yVsnU%2B%2BBaU0NUk8EKu0KLZdlcZ9qJcGmggxVcVu8bFD9pmO%2BqxSk6WsN9dH%2F1OkJ3YkrvPAkJDDHLvegyXM6ZWIsDBXHRXVM9lpOPjs8tw9ofVUqeMgPa16%2FSsMKZs6D1T08sZ2HvsCINGkwkM7c3CYMxaJ09ZW7k8UlGWN7HXS29wwRREMPjr4Pjx3ICDce9g8yjrbpjaD%2BZncv0M%2BapdCKCKFwt%2BJkjsEIR34eqWOdguO6qx%2F%2FZUacIsfRPgsls4lpZp8pDuublHk%2B1nPv90K1%2BQr7L0HsIo0S%2B1jIAKp1DRlCb7gByOjk0EnrNFXttqf3V8fyrZ7Lrg6ZLwtzhsqyliAJy89HWrLwBKCFuI%2FI%2BnzRS2OUbXsHZQ0CySGAVramjLk4%2B%2Bj8Y%2FAg%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231201T122659Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAUWMK3OMQB5SKV4PQ%2F20231201%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=78e48b7b8aef3c0055f086db46ab2a7f4e81dd102e9e4515c6cb25e83e7396db"
                            className="link"
                        >
                            Termos de uso
                        </Link>
                        <br />
                        <h6>DOCUMENTOS INSTITUCIONAIS</h6>
                        <Link
                            to="https://zebracambio.com.br/relatorio-de-ouvidoria/"
                            className="link"
                        >
                            Relatório de Ouvidoria
                        </Link>
                        <Link
                            to="https://zebracambio.com.br/relatorio-de-denuncia/"
                            className="link"
                        >
                            Relatório de Denúncia
                        </Link>
                    </div>
                    <div className="help">
                        <h6>AJUDA E SUPORTE</h6>
                        <Link to="https://zebracambio.com.br/contato/" className="link">
                            Contato
                        </Link>
                        <Link to="https://zebracambio.com.br/canal-de-denuncia/" className="link">
                            Canal de denúncia
                        </Link>
                        <Link to="https://zebracambio.com.br/faq/" className="link">
                            Central de ajuda - FAQ
                        </Link>
                        <br />
                        <img
                            src="https://assets.zebra.app.br/png/seals/abracam.png"
                            alt="Selo ABRACAM"
                            className="abracam"
                        />
                    </div>
                    <div className="contact">
                        <h6>FALE COM A ZEBRA</h6>
                        <Link to="tel:+551148633002" className="link">
                            Comercial 11 4863 3002
                        </Link>
                        <Link to="tel:+5508009420043" className="link">
                            Ouvidoria 0800 942 0043
                        </Link>
                        <Link to="tel:+5511995076440" className="link">
                            SAC 11 99507 6440
                        </Link>
                        <Link to="mailto:sac@zebracambio.com.br" className="link">
                            sac@zebracambio.com.br
                        </Link>
                        <Link to="tel:+551148391685" className="link">
                            Outros assuntos 11 4839 1658
                        </Link>
                        <p className="opening-hours">Horário de atendimento das 08h às 18h</p>
                    </div>
                </div>
                <div className="foot">
                    <p>© Copyright {anoAtual} Zebra Câmbio. Todos os direitos reservados. </p>
                </div>
            </div>
        </>
    );
};

export default Footer;
