import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/style.css";
import AppRoutes from "./Routes";
import { AuthProvider } from "contexts/AuthContext";
import { Analytics } from "@vercel/analytics/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <AuthProvider>
            <AppRoutes />
            <Analytics />
        </AuthProvider>
    </React.StrictMode>
);
