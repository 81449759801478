import React, { useEffect, useState } from "react";
import DownArrow from "assets/icons/down-arrow.svg";
import Dropbox from "../Dropbox/Dropbox";
import ModalityJson from "assets/json/modalities.json";

const Dropdown = ({ modality, setModality, setWay, way, alert, setAlert, setQuote, quote }) => {
    const [arrowIcon] = useState(DownArrow);
    const [displayStyle, setDisplayBox] = useState("none");
    const [modalityName, setModalityName] = useState(quote.modalityName);

    const openOptions = () => {
        // Adicionar a lógica para alterar o ícone da seta e aplicar a rotação
        if (displayStyle === "none") {
            document.querySelector(".arrow").style.transform = "rotate(180deg)";
            setDisplayBox("block");
        } else {
            document.querySelector(".arrow").style.transform = "rotate(0)";
            setDisplayBox("none");
        }
    };

    useEffect(() => {
        setModalityName(quote.modalityName);
    }, [quote.modalityName]);

    const updateModality = (name, cod) => {
        openOptions();
        setDisplayBox("none");
        setModalityName(name);
        setModality(cod);
        setAlert(false);
    };

    const pickWay = (w) => {
        setWay(w);
    };

    function getUniqueCategory(lista) {
        const uniqueCategories = [];

        lista.forEach((item) => {
            if (
                !uniqueCategories.includes(item.categoria) &&
                (item.transacao === "Fin-Venda" || item.transacao === "Importação")
            ) {
                uniqueCategories.push(item.categoria);
            }
        });

        return uniqueCategories;
    }

    const categoryList = getUniqueCategory(ModalityJson);

    return (
        <div>
            <div className="dropdown" id="dropdown" onClick={openOptions}>
                <div
                    className="select"
                    style={{
                        borderColor: alert === true ? "#f33f21" : "",
                    }}
                >
                    <p
                        style={{
                            color: alert === true ? "#f33f21" : "",
                        }}
                        className={modalityName === "Selecione uma modalidade" ? "placeholder" : ""}
                    >
                        {modalityName.length > 30
                            ? modalityName.substring(0, 30) + "..."
                            : modalityName}
                    </p>
                    <img src={arrowIcon} alt="Mais opções de moeda" className="arrow" />
                </div>
            </div>
            <Dropbox
                displayBox={displayStyle}
                modalityJson={ModalityJson}
                categoryList={categoryList}
                setModality={updateModality}
                modality={modality}
                setWay={pickWay}
                way={way}
                setQuote={setQuote}
            />
        </div>
    );
};

export default Dropdown;
