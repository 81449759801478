import { createContext, useState, useEffect } from "react";
import axios from "axios";

const authApi = "https://8klmbwrba7.execute-api.sa-east-1.amazonaws.com/login";
const validateApi = "https://8klmbwrba7.execute-api.sa-east-1.amazonaws.com/validate";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();

    useEffect(() => {
        const validateToken = async () => {
            const authToken = localStorage.getItem("authToken");

            if (authToken) {
                try {
                    const response = await axios.post(validateApi, { token: authToken });

                    if (response.data.user) {
                        setUser(response.data.user);
                    }
                } catch (err) {
                    localStorage.setItem("authToken", "");
                }
            }
        };
        validateToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signin = async (email, password) => {
        const data = {
            email: email,
            password: password,
        };

        try {
            if ((email, password)) {
                const response = await axios.post(authApi, data);
                localStorage.setItem("authToken", response.data.accessToken);

                setUser({
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    document: response.data.document,
                    apiToken: response.data.apiToken,
                });

                return true;
            }
        } catch {
            return false;
        }

        return false;
    };

    const logout = async () => {
        setUser(null);
        localStorage.removeItem("authToken");
    };

    return <AuthContext.Provider value={{ signin, logout, user }}>{children}</AuthContext.Provider>;
};
