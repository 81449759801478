import axios from "axios";

const api = axios.create({
    baseURL: "https://hook.us1.make.com/t4rv2ckgozp5ieermr8c9svnknaps2yy",
    headers: {
        "Content-Type": "application/json",
    },
});

export default api;
