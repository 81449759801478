const Button = (props) => {
    return (
        <button
            style={{
                backgroundColor: props?.backgroundColor,
                color: props?.fontColor,
                height: props?.height,
            }}
            onClick={props?.onClick}
        >
            {props?.icon !== undefined && <img src={props?.icon} alt={props?.name} />}
            {props?.name}
        </button>
    );
};

export default Button;
