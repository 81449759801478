/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import InputValue from "../InputValue/InputValue";
import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import AttachButton from "components/AttachButton/AttachButton";
import AttachIcon from "assets/icons/attach.svg";
import DoneButton from "assets/icons/done.svg";
import CloseButton from "assets/icons/close.svg";
import ConfirmedIcon from "assets/icons/confirmed.svg";
import apiQuote from "../../api/quote";
import apiDocument from "../../api/document";
import formatToBrl from "utils/FormatToBrl";
import Tooltip from "components/Tooltip/Tooltip";
import axios from "axios";
import { AuthContext } from "contexts/AuthContext";

const ExchangeBox = ({ coins, quote, setQuote, modality, step, setStep }) => {
    const [dropdownAlert, setDropdownAlert] = useState(false);
    const [checkboxAlert, setCheckboxAlert] = useState(false);
    const [attachAlert, setAttachAlert] = useState(false);
    const [inputAlert, setInputAlert] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [sendDocsButton, setSendDocsButton] = useState(false);
    const [sendQuotationButton, setSendQuotationButton] = useState(true);
    const [confirmButton, setConfirmButton] = useState(false);
    const [quoteClosed, setQuoteClosed] = useState({});
    const options = ["D0 (INSTANTÂNEO)", "D1", "D2"];
    let commercialTime = quote.price !== "0.0000";
    const auth = useContext(AuthContext);

    const updateQuote = (key, value) => {
        setQuote((prevQuote) => ({
            ...prevQuote,
            [key]: value,
        }));
    };

    const backToQuotation = () => {
        setConfirmButton(false);
        setSendQuotationButton(true);
    };

    useEffect(() => {
        commercialTime = quote.price !== "0.0000";
    }, [quote.price]);

    const changeView = () => {
        quote.modality === "" ? setDropdownAlert(true) : setShowDetails(!showDetails);
    };

    const redirectToTrader = () => {
        window.open(
            `https://wa.me/5511998951892?text=Olá trader,%0A%0AGostaria de ${
                quote.way === "enviar" ? "vender" : "comprar"
            } *${formatToBrl(quote?.coinQuote)} ${quote?.coin}*.%0A%0APara modalidade de *${
                modality?.nome
            }* e fluxo de transação *${quote?.flow}*.`,
            "_blank"
        );
    };

    const changeButtons = () => {
        if (quote.modality === "") {
            setDropdownAlert(true);
        } else if (quote.brlQuote < 150) {
            setInputAlert(true);
        } else if (quote.flow === "") {
            setCheckboxAlert(true);
            setInputAlert(false);
        } else {
            setSendQuotationButton(false);
            setConfirmButton(true);
            setInputAlert(false);
            setStep("confirm");
        }
    };

    const sendQuotation = async () => {
        setQuoteClosed(quote);

        if (
            quote.brlQuote > 0 &&
            quote.brlQuote <= 10000 &&
            (quote.coin.includes("USD") || quote.coin.includes("EUR"))
        ) {
            const confirmArea = document.getElementById("confirm-area");
            confirmArea.innerHTML = "<p class='center'>Executando ordem...</p>";
            let sent = false;

            const quoteCopy = { ...quote };

            quoteCopy.spreadPercent = 1.2;
            quoteCopy.priceSpread = (quote.price * (1 + quote.spread)).toFixed(2);

            try {
                const response = await apiQuote.post("/", quoteCopy);

                setQuoteClosed(quote);
                setConfirmButton(false);
                setSendDocsButton(true);
                modality?.documentos.length > 0 ? setStep("document") : setStep("sent");
            } catch (error) {
                confirmArea.innerHTML = "<p class='center'>Falha ao executar a ordem.</p>";
            }

            if (sendDocsButton) {
                const quoteApi = {
                    coin: quote.coin,
                    amount: quote.coinQuote,
                    quote: parseFloat(quote.price.replace(",", ".")),
                    liquidity: quote.flow,
                    modality: quote.modality,
                    ir: parseFloat(quote.irValue.replace(",", ".")),
                    iof: parseFloat(quote.iofValue.replace(",", ".")),
                    vet: quote.vet,
                    status: "CONCLUÍDO",
                };

                if (auth.user.id) {
                    const headers = {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                    };

                    const config = { headers };

                    await axios.post(
                        `https://8klmbwrba7.execute-api.sa-east-1.amazonaws.com/users/${auth.user.id}/transactions`,
                        quoteApi,
                        config
                    );
                }
            }
        }
    };

    const sendDocs = () => {
        if (quote.files.length >= modality.documentos.length && quote.files.length >= 0) {
            quote.files.forEach((obj) => {
                const formData = new FormData();
                formData.append("file", obj);

                apiDocument
                    .post(`/?user=${quote.user}`, formData)
                    .then((response) => {
                        //console.log("Resposta:", response.data);
                    })
                    .catch((error) => {
                        //console.error("Erro:", error);
                    });
            });
            setStep("sent");
        } else {
            setAttachAlert(true);
        }
    };

    return (
        <div className="exchange">
            <div
                className="exchange-box"
                id="exchange"
                style={{ backgroundColor: step === "document" || step === "sent" ? "black" : "" }}
            >
                <div
                    className="exchange-input"
                    style={{
                        display: !commercialTime
                            ? "none"
                            : showDetails || step === "document" || step === "sent"
                            ? "none"
                            : "flex",
                    }}
                >
                    <div>
                        <h4>MODALIDADE</h4>
                        <Dropdown
                            modality={modality}
                            setModality={(modality) => updateQuote("modality", modality)}
                            setWay={(way) => updateQuote("way", way)}
                            way={quote.way}
                            setAlert={setDropdownAlert}
                            alert={dropdownAlert}
                            setQuote={setQuote}
                            quote={quote}
                        />
                    </div>

                    <div>
                        <div className="input-infos">
                            <h4>BENEFICIÁRIO RECEBE</h4>
                            <p>R$ {quote.price === 0 ? "-,----" : quote.price}</p>
                        </div>
                        <InputValue
                            coins={coins}
                            quote={quote}
                            coin={(coin) => updateQuote("coin", coin)}
                            alert={setDropdownAlert}
                            inputAlert={inputAlert}
                            sendDocsButton={sendDocsButton}
                            disabled={confirmButton}
                        />
                    </div>
                    <div>
                        <div className="input-infos">
                            <h4>CUSTO DA OPERAÇÃO</h4>
                            <p>
                                {quote.brlQuote > 0 && quote.brlQuote < 150
                                    ? "Valor mínimo: R$150"
                                    : ""}
                            </p>
                        </div>
                        <InputValue
                            coins={coins}
                            quote={quote}
                            dropdown={false}
                            alert={setDropdownAlert}
                            inputAlert={inputAlert}
                            sendDocsButton={sendDocsButton}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <div className="flow">
                            <h4>FLUXO DA TRANSAÇÃO</h4>
                            <Tooltip text="D0, D1 e D2 referem-se aos prazos de liquidação para transferências de fundos. Esses prazos de liquidação indicam o número de dias úteis necessários para que os fundos sejam transferidos e estejam disponíveis na conta do beneficiário." />
                        </div>

                        <Checkbox
                            options={options}
                            flow={(flow) => updateQuote("flow", flow)}
                            setAlert={setCheckboxAlert}
                            alert={checkboxAlert}
                        />
                    </div>
                    <div className="btn-details">
                        {quote.brlQuote <= 10000 &&
                        (quote.coin.includes("USD") || quote.coin.includes("EUR")) ? (
                            <div>
                                <div
                                    className="calculate"
                                    style={{
                                        display: sendQuotationButton ? "block" : "none",
                                    }}
                                >
                                    <Button
                                        onClick={changeButtons}
                                        name="Fechar Cotação"
                                        height="45px"
                                        quote={quote}
                                        alert={setDropdownAlert}
                                    />
                                </div>
                                <div
                                    id="confirm-area"
                                    className="confirm"
                                    style={{
                                        display: confirmButton ? "flex" : "none",
                                    }}
                                >
                                    <img
                                        src={CloseButton}
                                        alt="Fechar"
                                        className="red"
                                        onClick={backToQuotation}
                                    />
                                    <p>Confirma a Operação?</p>
                                    <img
                                        src={DoneButton}
                                        alt="Confirmar"
                                        className="green"
                                        onClick={sendQuotation}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                <Button
                                    name="Fechar com Trader"
                                    height="45px"
                                    quote={quote}
                                    onClick={redirectToTrader}
                                    alert={setDropdownAlert}
                                />
                            </>
                        )}
                        <div className="infos">
                            <p onClick={changeView} className="details">
                                {quote.brlQuote > 10000
                                    ? "Valor negociado somente com um trader"
                                    : quote.coin.includes("USD") || quote.coin.includes("EUR")
                                    ? "Ver detalhes"
                                    : "Moeda negociada somente com um trader"}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="details"
                    style={{
                        display: showDetails ? "block" : "none",
                    }}
                >
                    <div>
                        <div className="info-line">
                            <div className="flex">
                                <p>Câmbio</p>
                                <Tooltip
                                    height="17px"
                                    text="Cotação do câmbio sem impostos ou taxas."
                                />
                            </div>
                            <p>1 BRL = {quote?.price && quote?.price.replace(".", ",")} USD</p>
                        </div>
                        <div className="info-line">
                            <div className="flex">
                                <p>Spread {quote?.spread * 100}%</p>
                                <Tooltip height="17px" text="Custo da operação." />
                            </div>
                            <p>R$ {quote?.spreadValue && formatToBrl(quote?.spreadValue)}</p>
                        </div>
                        <div className="info-line">
                            <div className="flex">
                                <p>IOF</p>
                                <Tooltip
                                    height="17px"
                                    text="O IOF é um imposto federal cobrado sobre diversas operações financeiras, como operações de crédito, câmbio, seguro e operações de títulos e valores mobiliários."
                                />
                            </div>
                            <p>R$ {quote?.iofValue && quote?.iofValue}</p>
                        </div>
                        <div className="info-line">
                            <div className="flex">
                                <p>IR</p>
                                <Tooltip height="17px" text="Imposto de renda." />
                            </div>
                            <p>R$ {quote?.irValue && quote?.irValue}</p>
                        </div>
                        <div className="info-line">
                            <div className="flex">
                                <p>Taxa administrativa</p>
                                <Tooltip height="17px" text="Taxa administrativa da transação." />
                            </div>
                            <p>R$ {formatToBrl(quote?.administrationFee)}</p>
                        </div>
                        <div className="info-line">
                            <div className="flex">
                                <p>Valor da transacionado</p>
                                <Tooltip
                                    height="17px"
                                    text="Valor em moeda nacional que será transacionado."
                                />
                            </div>
                            <p>R$ {formatToBrl(quote?.brlQuote)}</p>
                        </div>
                    </div>
                    <div className="vet">
                        <div className="flex">
                            <p>VET</p>
                            <Tooltip
                                height="17px"
                                text="
                                Valor final que o cliente pagará ou receberá pela compra ou venda de moeda estrangeira. O VET inclui a taxa de câmbio, o IOF e qualquer outra tarifa que possa ser cobrada."
                            />
                        </div>
                        <p>
                            1 BRL = {quote?.vet && quote?.vet.toFixed(4).replace(".", ",")}{" "}
                            {quote?.coin}
                        </p>
                    </div>
                    <div onClick={changeView}>
                        <Button
                            name="Voltar para simulação"
                            backgroundColor="#000000"
                            fontColor="#FFFFFF"
                            height="45px"
                        />
                    </div>
                </div>
                <div
                    className="quote-sent"
                    style={{
                        display: step === "document" || step === "sent" ? "flex" : "none",
                    }}
                >
                    <img src={ConfirmedIcon} alt="Confirmado" />

                    {modality?.documentos.length === 0 || step === "sent" ? (
                        quote.way !== "" ? (
                            <>
                                <div className="center">
                                    <h4>Aguardando pagamento</h4>
                                    <p>
                                        Efetue o pagamento de{" "}
                                        <b>R$ {formatToBrl(quoteClosed.brlQuote)}</b> via PIX ou
                                        TED, em seguida, envie o comprovante por e-mail.
                                    </p>
                                </div>
                                <div className="center">
                                    <h4>Conta de pagamento</h4>
                                    <p>
                                        PIX <span className="green">40.333.582/0001-52</span>
                                        <br />
                                        <br />
                                        Banco{" "}
                                        <span className="green">Proseftur Corretora (556)</span>
                                        <br />
                                        Agência <span className="green">0001</span>
                                        <br />
                                        Conta <span className="green">2023080-3</span>
                                        <br />
                                    </p>
                                </div>
                                <a href="mailto:financeiro@zebracambio.com.br" className="link">
                                    financeiro@zebracambio.com.br
                                </a>
                            </>
                        ) : (
                            <>
                                <h4>Agora é com a gente!</h4>
                                <p>
                                    Estamos cuidando de tudo. Em breve, você receberá uma mensagem
                                    de um dos nossos traders.
                                </p>
                                <p>
                                    Dúvidas? &nbsp;
                                    <a href="http://wa.me/5511998951892" className="link">
                                        Fale com um especialista.
                                    </a>
                                </p>
                            </>
                        )
                    ) : (
                        <>
                            <div>
                                <p className="transaction">Transação Realizada!</p>
                                {quote.way === "enviar" ? (
                                    <p>
                                        {formatToBrl(quoteClosed?.brlQuote)} BRL <span> ⭢ </span>{" "}
                                        {formatToBrl(quoteClosed?.coinQuote)} {quoteClosed?.coin}
                                    </p>
                                ) : (
                                    <p>
                                        {formatToBrl(quoteClosed?.coinQuote)} {quoteClosed?.coin}{" "}
                                        <span> ⭢ </span> {formatToBrl(quoteClosed?.brlQuote)} BRL
                                    </p>
                                )}
                            </div>
                            <p>
                                Por favor, anexe os documentos citados na terceira etapa, para que
                                sua transação seja validada.
                            </p>
                            <div className="attach-buttons">
                                {modality?.documentos.map((document, index) => (
                                    <AttachButton
                                        key={index}
                                        backgroundColor="#cdcdcd"
                                        icon={AttachIcon}
                                        name={index + 1}
                                        cod={document.split(" ")[0]}
                                        quote={quote}
                                        attachAlert={attachAlert}
                                        documentName={document}
                                    />
                                ))}
                                <Button name="Enviar" height="45px" onClick={sendDocs} />
                            </div>
                        </>
                    )}
                </div>
                <div
                    style={{
                        display: !commercialTime ? "flex" : "none",
                    }}
                    className="closed"
                >
                    <b>Serviço indisponível fora do horário comercial.</b>
                    <br />
                    <p>
                        Horário de funcionamento: <br />
                        de segunda a sexta, das 09:00 às 17:00.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ExchangeBox;
