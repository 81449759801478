import axios from "axios";

const api = axios.create({
    baseURL: "https://hook.us1.make.com/9p8rlmg3mv6kzbfgaaj8ogt2ajvyfeoc",
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

export default api;
