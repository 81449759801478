import DownArrow from "../../assets/icons/down-arrow.svg";
import UpArrow from "../../assets/icons/up-arrow.svg";

const Dropbox = ({
    displayBox,
    modalityJson,
    categoryList,
    modality,
    setWay,
    way,
    setModality,
    setQuote,
    alert,
}) => {
    const pickOption = (id) => {
        setQuote((prev) => ({
            ...prev,
            modalityId: id,
        }));
    };

    const pickWay = (w) => {
        setWay(w);
    };

    const openOptions = (e) => {
        const options = document.getElementById(e);
        let elementArrow = document.getElementById(`${e}-icon`);
        if (elementArrow.src.includes("up")) {
            elementArrow.src = DownArrow;
        } else {
            elementArrow.src = UpArrow;
        }
        options.hidden = !options.hidden;
    };

    return (
        <div
            className="dropbox"
            style={{
                display: displayBox,
            }}
        >
            <div>
                <div className="way-options">
                    <p
                        onClick={() => pickWay("enviar")}
                        className={way === "enviar" ? "selected" : ""}
                    >
                        Enviar / Importar
                    </p>
                    {/* <p
                        onClick={() => pickWay("receber")}
                        className={way === "receber" ? "selected" : ""}
                    >
                        Receber / Exportar
                    </p> */}
                </div>

                <div className="dropbox-options" style={{ display: way === "" ? "none" : "" }}>
                    {categoryList.map((category) => (
                        <div className="category-options" key={category}>
                            <div className="category" onClick={() => openOptions(category)}>
                                <p>{category}</p>
                                <img src={DownArrow} alt="Opções" id={`${category}-icon`} />
                            </div>
                            <div id={category} hidden>
                                {modalityJson.map((m) =>
                                    m.categoria === category ? (
                                        <div
                                            className="option"
                                            onClick={() => pickOption(m.id)}
                                            key={m.id}
                                        >
                                            <div
                                                className="info-name"
                                                style={{
                                                    fontWeight: m.id === modality?.id ? "600" : "",
                                                }}
                                            >
                                                <p className="info">{m.detalhe}</p>
                                                <p className="name">
                                                    {m.id === modality?.id ? "✓ " : ""}
                                                    {m.nome}
                                                </p>
                                            </div>
                                            {/* <p className="iof">IOF: {m.iof}%</p> */}
                                        </div>
                                    ) : null
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="option">
                        <div
                            className="info-name"
                            style={{
                                fontWeight: "0000" === modality?.id ? "600" : "",
                            }}
                        >
                            <p className="info"></p>
                            <a href="https://wa.me/5511998951892" className="not-found">
                                {"Não encontrou? Fale com um trader"}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dropbox;
