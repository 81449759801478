const CoinsOptions = ({ display, coins, setCoin }) => {
    const getIcon = (coin) => {
        return `https://assets.zebra.app.br/webp/coins/${coin}.webp`;
    };

    const pickOption = (coin) => {
        setCoin(coin);
    };

    if (!coins) {
        return (
            <div
                className="coin-window"
                style={{
                    display: display,
                }}
            >
                <div className="coins-options">
                    <div className="option">
                        {/* <img src={UsdCoin} alt="dasdas" /> */}
                        <p className="coin">----</p>
                        <p className="coin-name">------</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className="coin-window"
            style={{
                display: display,
            }}
        >
            <div className="coins-options">
                {coins.map((coin) => (
                    <div
                        className="option"
                        onClick={() => pickOption(coin.from)}
                        key={coin.from}
                    >
                        <img src={getIcon(coin.from)} alt={coin.icon} />
                        <p className="coin">{coin.from}</p>
                        <p className="coin-name">{coin.name.split("/")[0]}</p>
                        <p className="price">
                            R$&nbsp;
                            {coin.value === null ? "-.--" : coin.bid.toFixed(2)}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CoinsOptions;
