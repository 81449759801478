import Button from "components/Button/Button";
import React from "react";
import { useNavigate } from "react-router-dom";

const Policy = () => {
    const navigator = useNavigate();

    const acceptPolicy = () => {
        localStorage.setItem("policy", true);
        navigator("/cotacao");
    };

    return (
        <div className="policy-page">
            <div className="content">
                <br />
                <p className="highlight">Aceite os termos antes de usar a plataforma</p>
                <br />
                <h2>Termos de Uso da Zebra Online</h2>
                <br />
                Os termos deste contrato (“Termos de Uso”) regem o relacionamento entre cada pessoa
                física ou jurídica residentes, domiciliadas ou com sede no Brasil, conforme
                aplicável, (“CLIENTE ZEBRA”) e a Proseftur Corretora de Câmbio S.A. (Nome comercial
                ZEBRA CÂMBIO) para utilização dos serviços, produtos e conteúdos oferecidos
                (“Serviços”) através da ZebraOnline da ZEBRA CÂMBIO, que inclui o website e o
                aplicativo mobile (“ZebraOnline”). A ZebraOnline foi desenvolvida e é
                disponibilizada como um produto da ZEBRA CÂMBIO. Ao utilizar a ZebraOnline, o
                CLIENTE ZEBRA manifesta estar ciente destes Termos de Uso que regem sua relação com
                a ZEBRA CÂMBIO, os quais devem ser lidos em conjunto com a respectiva Política de
                Privacidade. A utilização da ZebraOnline, parcial ou integralmente, importa na
                imediata aceitação dos seus respectivos Termos de Uso e Política de Privacidade.
                Integram estes Termos de Uso, como se neles estivessem transcritas, as legislações
                aplicáveis à matéria, em especial as Circulares do Banco Central do Brasil (“BACEN”)
                nº 3.689/2013, nº 3.690/2013, , Resolução BCB n° 277/2022, nº 3.978/20, Resolução do
                Conselho Monetário Nacional (“CMN”) nº 3.954/2011, a Lei Geral de Proteção de Dados
                nº 13.709/18, a Lei nº 4.131/1962, o Decreto nº 23.258/1933, a Lei nº 4.595/1964, a
                Lei nº 14.286/2021 (a partir de sua vigência).
                <br />
                <br />
                <h2>Descritivo e Finalidade da Zebra Online</h2>
                <br />A ZebraOnline tem por finalidade oferecer o serviço de intermediação entre o
                CLIENTE ZEBRA e instituição financeira autorizada a operar em câmbio ("Corretora de
                Câmbio"), com o intuito de conferir celeridade e agilidade aos procedimentos de
                envio e de recebimento internacionais de valores. A ZEBRA CÂMBIO está sediada no
                Brasil, sociedade limitada, inscrita no Cadastro Nacional da Pessoa Jurídica
                ("CNPJ") sob o nº 24.765.993/0001-50, que atua como corretora de câmbio conforme
                reconhecido pelo BACEN. Os Serviços oferecidos pela ZebraOnline facilitam o
                intermédio entre o CLIENTE ZEBRA e a Corretora, possibilitando a realização e
                efetivação de conversão de moeda nacional para moeda estrangeira ou vice e versa por
                intermédio da ZebraOnline e envio de valores entre contas bancárias no mundo. O
                acesso e a criação de um Cadastro na ZebraOnline são gratuitos e livres de quaisquer
                custos ou despesas para os CLIENTE ZEBRA. A contratação de Transações pelo CLIENTE
                ZEBRA através da ZebraOnline está sujeita a custos informados ao CLIENTE ZEBRA
                quando da contratação da respectiva Transação. Toda e qualquer contratação de
                Transações será objeto de prévio e expresso consentimento do CLIENTE ZEBRA. A
                ZebraOnline é oferecida na forma em que se encontra ("as is"), podendo ser
                modificada a qualquer tempo ou deixar de existir, sem a necessidade de notificação
                prévia ou autorização do CLIENTE ZEBRA. Para os fins a que se destinam os Termos de
                Uso e a Política de Privacidade, considera-se: (i) moeda nacional: a moeda oficial
                de uso em território brasileiro, no caso o Real; e (ii) moeda estrangeira: toda e
                qualquer moeda oficial vigente em outro país que a Zebra aceitem trocar pelo seu
                contravalor em moeda nacional de acordo com as cotações de mercado então vigentes.
                <br />
                <br />
                <h2>Cadastro e Aprovação na ZEBRA CÂMBIO</h2>
                <br />
                Para acessar as funcionalidades da ZebraOnline, o CLIENTE ZEBRA precisará
                cadastrar-se, informando obrigatoriamente alguns dados, que serão armazenados para
                fins de cumprimento de obrigações legais e que poderão ser enriquecidos pela ZEBRA
                CÂMBIO, ou seja, confirmados ou complementados por meio de consultas a birôs,
                sistema de cadastro e informações e bancos de dados, públicos e/ou privados,
                incluindo operados ou disponibilizados pelo BACEN ("Sistemas de Consulta"), e, caso
                aplicável, o enriquecimento poderá ser realizado em relação aos representantes da
                pessoa jurídica, conforme previsto na Política de Privacidade e aceito pelo CLIENTE
                ZEBRA ("Cadastro"). O Cadastro resultará na criação de um login e senha de acesso
                que identificam o CLIENTE ZEBRA na ZebraOnline. A ZEBRA CÂMBIO poderá solicitar
                documentos comprobatórios relacionados ao exercício de poderes para realizar
                Transações, incluindo procurações porventura outorgadas para terceiros, com firma
                reconhecida e mandato não superior a 1 (um) ano. Ao acessar a ZebraOnline e para
                realização do Cadastro, o CLIENTE ZEBRA expressamente concorda e autoriza a ZEBRA
                CÂMBIO e ter acesso a informações a seu respeito constantes de sistemas de consulta,
                inclusive acerca de processos e procedimentos judiciais ou administrativos, as quais
                serão também utilizadas para fins de atendimento da legislação e regulamentação
                aplicáveis, bem como para execução de procedimentos de prevenção e combate à lavagem
                de dinheiro e ao financiamento ao terrorismo. Em se tratando de Cadastro de pessoas
                jurídicas, o CLIENTE ZEBRA concorda e autoriza que as consultas junto aos Sistemas
                de Consulta envolvam também (i) informações sobre os respectivos beneficiários
                finais das sociedades, os percentuais de participação e eventual existência de
                restrições e/ou sanções; e (ii) consultas do código de Classificação Nacional de
                Atividades Econômicas - CNAE e do âmbito de atuação da sociedade, inclusive para
                confirmar se a sociedade estaria obrigada a cumprir obrigações adicionais previstas
                na legislação para prevenção à lavagem de dinheiro e financiamento ao terrorismo. A
                fim de mitigar os riscos de utilização da ZebraOnline para fins ilícitos e por
                constituir importante medida de prevenção à lavagem de dinheiro e financiamento ao
                terrorismo e outros ilícitos, a ZEBRA CÂMBIO se reserva o direito de, a qualquer
                tempo, bloquear o CLIENTE ZEBRA ou recusar a realização de determinada Transação
                caso identifique que o CLIENTE ZEBRA estaria obrigado mas não possui registro no
                Sistema de Controle de Atividades Financeiras – SISCOAF em conformidade com a
                regulamentação aplicável. A fim de mitigar os riscos de utilização da ZebraOnline
                para fins ilícitos, caso a ZEBRA CÂMBIO identifique que a pessoa jurídica exerce
                atividades compatíveis com aquelas arroladas no artigo 9º da Lei 9.613/98, porém não
                possui registro no SISCOAF, comunicará imediatamente o CLIENTE ZEBRA, e lhe
                concederá o prazo de 2 (dois) meses para efetivação do procedimento previsto em lei.
                Caso o prazo se escoe e o cadastro de pessoa obrigada não seja realizado, ou haja a
                mera intenção expressa de não fazê-lo, a ZEBRA CÂMBIO reserva-se o direito de
                bloquear o cliente, até que a pendência seja sanada. Por constituir importante
                medida de prevenção à Lavagem de Dinheiro e Financiamento do Terrorismo, o bloqueio
                a que se refere o item 2.1.5 destes Termos não obedecerá a oportunidades comerciais.
                Sendo mandatório quando não for verificado o registro no SISCOAF em prazo
                pré-determinado, comunicado e consentido. O CLIENTE ZEBRA é o único responsável pelo
                sigilo de sua senha e todas as atividades realizadas em seu Cadastro, devendo entrar
                em contato com a ZEBRA CÂMBIO em caso de qualquer suspeita de uso indevido de seu
                login e senha, por qualquer terceiro. Fica resguardado à ZEBRA CÂMBIO o direito de
                aprovar ou reprovar as concessões ou aumentos de limites com base nas suas
                diretrizes internas. A realização do Cadastro importa em declaração, pelo CLIENTE
                ZEBRA, de que as informações e/ou documentos prestados pelo CLIENTE ZEBRA para
                realização do Cadastro ou de Transações (incluindo quaisquer informações preenchidas
                automaticamente pela ZebraOnline a partir de Sistemas de Consulta) são verdadeiros,
                completos e atualizados e não distorcem nenhuma informação material acerca do
                CLIENTE ZEBRA, sendo o CLIENTE ZEBRA único e exclusivamente responsável, civil e
                criminalmente, por essas informações, estando o número de seu CPF e CNPJ válidos e
                aptos perante o Ministério da Economia, assim como as demais informações e
                documentos inseridos na ZebraOnline para a realização de Transação. O CLIENTE ZEBRA
                está ciente de que a ZEBRA CÂMBIO, poderá adotar as medidas cabíveis, previstas em
                leis e outras normas de caráter infraconstitucional, a fim de reprimir o mau uso da
                ZebraOnline ocasionado em virtude da inserção de dados e informações que não sejam
                compatíveis com a realidade ou contrários às regras postas para utilização da
                ZebraOnline. Caso sejam identificados, a qualquer tempo, dados ou informações
                incorretas, falsas ou contrárias às regras que regem a utilização da ZebraOnline, a
                ZEBRA CÂMBIO reserva-se o direito de não concluir o Cadastro, de bloquear o Cadastro
                já existente e/ou de não dar continuidade a uma Transação até que as informações
                sejam corrigidas e/ou complementadas pelo CLIENTE ZEBRA. Via de regra, o
                compartilhamento de dados do CLIENTE ZEBRA ocorrerá somente entre as partes destes
                Termos de Uso, em virtude de solicitação judicial ou requisição de Autoridade
                competente para tanto. As demais situações de compartilhamento dos dados pessoais
                dos CLIENTE ZEBRA estão descritas nestes Termos de Uso e na Política de Privacidade,
                e seguirão igualmente os limites e parâmetros previstos em lei e nos atos normativos
                expedidos pelo BACEN. . A aceitação de CLIENTE ZEBRA/Clientes na ZebraOnline Zebra,
                se submete não somente às normas expedidas pelo Banco Central do Brasil. A ZEBRA
                CÂMBIO poderá, a qualquer tempo, solicitar novos documentos e informações que
                entenda necessários para fins do Cadastro, inclusive motivada por alterações na
                regulamentação aplicável ou mesmo simples atualizações de suas diretrizes e/ou
                procedimentos internos e até que sejam recebidas tais informações e documentos,
                poderá se reservar o direito de não realizar qualquer nova Transação.
                <br />
                <br />
                <h2>Termos de Acesso e de Utilização da Zebra Online</h2>
                <br />
                Ao acessar a ZebraOnline, o CLIENTE ZEBRA expressa de imediato a sua aceitação,
                plena e sem reservas, aos Termos de Uso, Política de Privacidade e demais regras que
                regulam a sua utilização. Da mesma forma, o CLIENTE ZEBRA se compromete a observar e
                respeitar as leis em vigor, utilizando a ZebraOnline apenas para fins lícitos e que
                respeitem quaisquer direitos de terceiros. O CLIENTE ZEBRA, ao acessar a
                ZebraOnline, também declara (i) possuir plenos poderes, capacidade, legitimidade e
                todas autorizações necessárias para aceitar estes Termos de Uso e a Política de
                Privacidade, para realizar quaisquer Transações, assumir obrigações e prestar
                quaisquer declarações e garantias através da ZebraOnline, para fins da realização de
                quaisquer Transações, independentemente da natureza ou valor, (ii) não estar agindo
                em nome de mandante oculto ou de um terceiro beneficiário. A ZEBRA CÂMBIO se reserva
                ao direito de aceitar documentos em apenas três idiomas, sendo eles, português,
                inglês e espanhol. Documentos em outros idiomas deverão ser traduzidos e possuir
                alguma forma de confirmação de autenticidade disponível no país de origem, como
                forma de atestar a veracidade das informações neles contidas. O CLIENTE ZEBRA é o
                único responsável pela destinação dos recursos e concorda em eximir a ZEBRA CÂMBIO
                de qualquer responsabilidade sobre o uso dos recursos enviados ou recebidos. A ZEBRA
                CÂMBIO fornece seus Serviços e viabiliza Transações para envio ou recebimento apenas
                nos países aqui listados, os quais possuem leis e regulamentações comerciais e
                tributárias próprias. O CLIENTE ZEBRA é o único responsável por analisar e cumprir
                essas legislações eximindo a ZEBRA CÂMBIO de quaisquer responsabilidades delas
                decorrentes. O CLIENTE ZEBRA deve verificar e respeitar a lista de países que a
                ZEBRA CÂMBIO viabiliza transações, pondendo a ZEBRA CÂMBIO cancelar qualquer
                transação realizada com países não contemplados na lista.
                <br />
                <br />
                <h2>Transações e Diretrizes ao CLIENTE ZEBRA</h2>
                <br />
                Após o cadastro na ZEBRA CÂMBIO, o CLIENTE ZEBRA está apto a realizar operações de
                envio de valores em moeda estrangeira. A contratação da Transação está sujeita ao
                atendimento, a critério da ZEBRA CÂMBIO das disposições regulamentares aplicáveis,
                que exigem, dentre outras questões, a comprovação da legalidade, legitimidade e
                fundamentação econômica da Transação pretendida. Para esses fins, a depender da
                natureza, a contratação da Transação poderá estar sujeita à solicitação e análise de
                documentos comprobatórios. Ou seja, a Transação somente poderá ser contratada uma
                vez que a ZEBRA CÂMBIO tenha aprovado o dossiê dos documentos apresentados pelo
                CLIENTE ZEBRA, a seu exclusivo critério. É de responsabilidade exclusiva do CLIENTE
                ZEBRA a comprovação da legalidade, legitimidade e fundamentação econômica da
                pretendida transação por meio de documentação idônea capaz de atestar esses
                requisitos. Qualquer solicitação de documentos pela ZEBRA CÂMBIO não deverá ser
                entendida pelo CLIENTE ZEBRA como orientação, por parte da ZEBRA CÂMBIO, sobre como
                se deve proceder para formalização de aspectos contratuais ou legais envolvidos. O
                período de análise da ZEBRA CÂMBIO pode variar de acordo com a complexidade de cada
                Transação, do volume de operações em semelhante status, ou mesmo em razão da
                necessidade de complementação, por parte do CLIENTE ZEBRA. Por esse motivo, é
                importante que o CLIENTE ZEBRA inicie as tratativas com a ZEBRA CÂMBIO com
                antecedência aos prazos eventualmente pactuados em relações jurídicas impactadas
                pelas efetivação das Transações. A ZEBRA CÂMBIO utiliza outros prestadores de
                serviços no Brasil e exterior para auxiliá-la no fornecimento dos Serviços e na
                contratação das Transações para o CLIENTE ZEBRA. Com estes, somente serão
                compartilhados os dados imprescindíveis à efetivação das Operações. As cotações de
                câmbio presentes na ZebraOnline são ofertadas em tempo real e refletem as condições
                de mercado aplicáveis no momento da consulta e, portanto, estão sujeitas a
                oscilações. Uma vez contratada a Transação, não é possível renegociar o valor da
                cotação, que inclui a taxa de câmbio e demais custos para sua realização. Para que
                seja possível a renegociação da cotação contratada, o CLIENTE ZEBRA deverá arcar com
                todos os custos e prejuízos ocasionados pelo cancelamento. Mediante a aprovação de
                Transação na ZebraOnline, o CLIENTE ZEBRA concorda e autoriza a aplicação das taxas
                de câmbio informadas na ZebraOnline no momento da contratação da Transação. Para
                fins de definição da taxa de câmbio, é apresentada pela ZebraOnline a taxa de
                mercado aplicável no momento da contratação da Transação (e não da identificação do
                crédito dos recursos, em relação às operações de recebimento). De acordo com as
                práticas de mercado e regulamentação aplicável, a taxa de câmbio para envio de
                recursos do Brasil ao exterior é acordada antes de o valor em moeda nacional ter
                sido transferido para a conta da ZEBRA CÂMBIO. Após travada a cotação da taxa de
                câmbio qualquer renegociação desta por iniciativa do CLIENTE ZEBRA somente será
                aceita mediante ressarcimento de eventuais custos e prejuízos que serão cobrados da
                ZEBRA CÂMBIO para desfazimento de qualquer operação já contratada para proteção
                contra oscilação cambial. A ZEBRA CÂMBIO cobra uma taxa para cada Transação
                realizada pelo CLIENTE ZEBRA, cujo valor é informado em momento prévio à conclusão
                da Transação e deduzido do montante total da Transação contratada pelo CLIENTE
                ZEBRA. Em caso de devolução de recursos oriundos de uma Transação em razão de
                preenchimento incorreto dos dados bancários pelo CLIENTE ZEBRA, o CLIENTE ZEBRA
                poderá reenviar a solicitação de transferência de recursos pela Zebra Cambio após a
                correção dos dados incorretos, estando ciente que poderá haver custos (tarifas e
                custos cobrados pelo ZEBRA CÂMBIO e/ou pela instituição bancária no exterior
                relacionadas ao retorno da ordem ou alterações referentes aos novos dados), somente
                o saldo líquido será disponibilizado para reenvio, observado o disposto na cláusula
                4.6.1. abaixo. Privilegiando a transparência na atividade desenvolvida, a ZEBRA
                CÂMBIO disponibilizará ao CLIENTE ZEBRA, após a realização de uma Transação, um
                resumo das condições pactuadas para a Transação, incluindo informações sobre a taxa
                de câmbio, as taxas cobradas e as datas limite para recebimento e pagamento de
                valores, sem prejuízo da assinatura do respectivo contrato de câmbio entre o CLIENTE
                ZEBRA e o ZEBRA CÂMBIO. Caso a Transação contratada pelo CLIENTE ZEBRA seja
                reprovada por restrições de qualquer natureza, o montante será devolvido para a
                conta corrente da qual a transferência bancária foi originada, e o envio ou
                recebimento não será realizado. Nesse cenário, os custos operacionais, tais como
                taxa de estorno e custos, poderão ser debitados. Se entender necessário, o ZEBRA
                CÂMBIO poderá demandar informações e documentos adicionais do CLIENTE ZEBRA e, se o
                CLIENTE ZEBRA não quiser fornecer as informações ou documentos solicitados ou não os
                possuir, os recursos poderão ser estornados para a conta corrente proveniente dos
                valores, deduzidos os eventuais custos operacionais em que incorreram os agentes
                responsáveis pela Transação. As Transações realizadas por intermédio da ZebraOnline
                estão sujeitas à investigação de órgãos de controle de transações internacionais,
                assim como checagens internas de compliance conduzidas pelo ZEBRA CÂMBIO e demais
                instituições envolvidas na liquidação de Transações. A ZEBRA CÂMBIO não possui
                qualquer vínculo ou ingerência sobre os procedimentos conduzidos por estas
                instituições e não se responsabiliza por eventuais atrasos nos envios ou
                recebimentos decorrentes destas práticas, tampouco pelo eventual cancelamento de
                operações por solicitação de tais instituições. Dentro dos limites legais
                aplicáveis, a ZEBRA CÂMBIO compromete-se a manter o CLIENTE ZEBRA sempre informado a
                respeito de tais questões que venha a tomar conhecimento que possam acarretar atraso
                e cancelamento de Transações. A ZEBRA CÂMBIO reserva-se o direito de solicitar ao
                CLIENTE ZEBRA quaisquer informações e/ou documentos adicionais, a qualquer tempo ou
                mesmo após a liquidação de Transações, em atendimento a solicitações que venham a
                ser formuladas pelo ZEBRA CÂMBIO e/ou demais instituições envolvidas nas Transações.
                As Transações realizadas através da ZebraOnline podem estar sujeitas a custos
                adicionais cobrados pelas instituições bancárias utilizadas pelo CLIENTE ZEBRA, pelo
                pagador ou pelo recebedor dos recursos no exterior. A ZEBRA CÂMBIO não tem qualquer
                ingerência sobre a cobrança desses custos adicionais e não se responsabiliza pelos
                eventuais descontos oriundos de alguma cobrança realizada por tal instituição
                bancária. O CLIENTE ZEBRA deverá se certificar dos custos que possam ser aplicados
                aos envios e recebimentos objeto de uma Transação por parte das instituições
                bancárias utilizadas pelo CLIENTE ZEBRA, pelo pagador ou pelo recebedor dos recursos
                no exterior.
                <br />
                <br />
                <h2>Envio de valores ao exterior</h2>
                <br />A ZEBRA CÂMBIO informará, ao final da contratação de cada Transação, a conta
                bancária e a data limite para realização do pagamento em moeda nacional para fins da
                Transação. A ZEBRA CÂMBIO somente enviará os valores à conta bancária internacional
                indicada pelo CLIENTE ZEBRA após receber a confirmação do pagamento. O pagamento
                deverá ser realizado a partir de uma conta bancária de mesma titularidade do CLIENTE
                ZEBRA. A data e o horário limite para a realização do pagamento serão apresentados
                quando o pedido do CLIENTE ZEBRA for efetivado na ZebraOnline. Este pagamento poderá
                ser feito através de transferência eletrônica disponível (TED) ou pagamento
                instantâneo (PIX). A ZEBRA CÂMBIO não aceita nenhuma outra forma de pagamento. O
                CLIENTE ZEBRA deverá transferir os recursos em moeda nacional à conta bancária no
                valor exato da operação, conforme instruções. A ZEBRA CÂMBIO não aceita depósito em
                espécie como forma de pagamento. Nesse sentido, se o CLIENTE ZEBRA ainda assim
                realizar o depósito, a devolução desses valores só poderá ser efetuada caso o
                CLIENTE ZEBRA tenha conta corrente no Brasil em seu nome. A ZEBRA CÂMBIO, por
                questões de segurança, reserva-se o direito de não realizar a devolução de valores
                para contas de titularidade de terceiros. Este procedimento é igualmente aplicável a
                quaisquer outras formas de pagamento não aceitas. A instituição bancária
                internacional recebedora dos recursos é a responsável por creditar os valores na
                conta indicada pelo CLIENTE ZEBRA. A ZEBRA CÂMBIO não possui controle operacional
                sobre essas instituições e não cobre as despesas que poderão ser cobradas por elas,
                bem como não se responsabiliza pela eventual demora em creditar os valores
                transferidos na conta indicada. Nesse sentido, recomenda-se que o CLIENTE ZEBRA se
                informe acerca das práticas da instituição bancária que receberá os valores
                transferidos e dos custos por ela cobrados, anteriormente à contratação da
                Transação. O CLIENTE ZEBRA deverá proceder com extrema cautela no momento de
                preenchimento dos dados como montante a ser enviado e sua natureza. Uma vez
                confirmada a contratação de uma Transação e após o envio de valores, caso o CLIENTE
                ZEBRA tenha cometido algum erro de digitação ou de qualquer outro tipo, e a
                instituição bancária internacional recebedora tenha acatado a transferência com base
                nas informações prestadas pelo CLIENTE ZEBRA, a ZEBRA CÂMBIO não se
                responsabilizarão pela devolução do dinheiro por parte do titular da conta indicada
                pelo CLIENTE ZEBRA. Caso este titular não concorde em devolver a quantia, a ZEBRA
                CÂMBIO não poderá ser responsabilizada pelo CLIENTE ZEBRA. A ZEBRA CÂMBIO
                compromete-se a envidar os melhores esforços para recuperação do valor, mas, em
                hipótese alguma, pode garantir que tal devolução será realizada, uma vez que foge do
                seu controle e de sua alçada jurídica obrigar a devolução dos valores pelo detentor
                da conta indicada pelo CLIENTE ZEBRA. O prazo para emissão do comprovante da
                Transferência, que garante que os recursos financeiros foram enviados para a conta
                indicada pelo CLIENTE ZEBRA, é de até 48 (quarenta e oito) horas úteis contadas da
                confirmação do recebimento da moeda nacional transferida pelo CLIENTE ZEBRA, ficando
                disponível na ZebraOnline.
                <br />
                <br />
                <h2>Operações</h2>
                <br />A ZEBRA CÂMBIO poderá atuar na qualidade de Corretora de Câmbio, nos termos
                das regras do BACEN, prestando serviço de pagamento e transferência internacional
                que, por meio de operação de câmbio de valor limitada à US$ 350.000,00 (trezentos e
                cinquenta mil dolares) ou seu equivalente em outras moedas, viabiliza a aquisição de
                bens e serviços de estabelecimentos comerciais no Brasil ou no exterior (os
                “Comerciantes”), conforme art. 49, inciso II, da Resolução BCB n° 277 de
                31/12/20227.2. O CLIENTE ZEBRA outorga poderes à ZEBRA CÂMBIO e aos parceiros da
                ZEBRA CÂMBIO para o recebimento do correspondente em moeda nacional ao pagamento
                devido aos Comerciantes, através dos meios disponibilizados pela ZEBRA CÂMBIO,
                realização de operações de câmbio e execução de remessas de fundos, em todos os
                casos, por conta e ordem do CLIENTE ZEBRA. Nos termos das normas aplicáveis, as
                operações de câmbio são sempre concluídas pelo ZEBRA CÂMBIO. Operações de câmbio
                serão realizadas em conformidade com os limites e condições estabelecidos pelas
                regras do CMN e BACEN. Ao efetuar pagamento em moeda nacional para a ZEBRA CÂMBIO, o
                CLIENTE ZEBRA concorda com as taxas de câmbio contratadas junto ao ZEBRA CÂMBIO . Os
                poderes outorgados nos termos desta Cláusula não incluem o pagamento ou retenção de
                quaisquer tributos devidos pelo CLIENTE ZEBRA ou pelo Comerciante, os quais deverão
                ser integralmente recolhidos pelo CLIENTE ZEBRA, na qualidade de contribuinte ou
                responsável. Caso as autoridades fiscais determinem à ZEBRA CÂMBIO o pagamento de
                tributos devidos pelo CLIENTE ZEBRA ou Comerciante, o CLIENTE ZEBRA deverá ressarcir
                integralmente a ZEBRA CÂMBIO pelo valor dos tributos e acréscimos legais
                eventualmente pagos, no prazo de 15 (quinze) dias. A obrigação de ressarcimento
                também abrange as despesas suportadas pela ZEBRA CÂMBIO no trâmite de processos
                administrativos ou judiciais ligados, direta ou indiretamente, às obrigações
                tributárias do CLIENTE ZEBRA. As informações fornecidas pela ZEBRA CÂMBIO, por
                qualquer meio, em relação às obrigações tributárias do CLIENTE ZEBRA, não devem ser
                interpretada como opinião legal especializada. Quaisquer dúvidas mantidas pelos
                CLIENTE ZEBRA em relação a tais obrigações deverão ser objeto de consulta
                independente, pelo CLIENTE ZEBRA, a profissionais especializados. Operações de EFX
                não apresentam contratos de câmbio em nome do CLIENTE ZEBRA; no entanto, em
                observância às regras do CMN e BACEN, e privilegiando a transparência de suas
                atividades, a ZEBRA CÂMBIO disponibiliza ao CLIENTE ZEBRA acesso a demonstrativo das
                Transações realizadas, contendo resumo das condições pactuadas. O demonstrativo
                incluirá, na medida em que aplicável: data, partes envolvidas, valores em moeda
                nacional e estrangeira, taxas de câmbio e taxas cobradas pela ZEBRA CÂMBIO e pelo
                ZEBRA CÂMBIO, subtotais relativos aos pagamentos e às transferências realizadas. A
                ZEBRA CÂMBIO não é responsável pela entrega ou pela qualidade dos bens e serviços
                adquiridos pelo CLIENTE ZEBRA, nem pelo conteúdo ou pelas informações veiculadas
                pelos Comerciantes em suas respectivas ZebraOnlines. A ZEBRA CÂMBIO atua
                exclusivamente como prestadora de serviços de pagamentos, sem qualquer vínculo com a
                relação comercial firmada entre CLIENTE ZEBRA e Comerciante. O papel da ZEBRA CÂMBIO
                é garantir que os recursos em moeda local por ela coletados sejam efetivamente
                entregues ao Comerciante. Assim, é de exclusiva responsabilidade do CLIENTE ZEBRA
                buscar todas as informações sobre os bens e serviços junto aos Comerciantes. O
                CLIENTE ZEBRA é único responsável pelas aquisições de bens e serviços e concorda em
                eximir a ZEBRA CÂMBIO de qualquer responsabilidade decorrente do uso dos recursos
                enviados ao ou recebidos do exterior. O CLIENTE ZEBRA também é o exclusivo
                responsável pelo cumprimento de legislações estrangeiras eventualmente aplicáveis às
                operações realizadas por intermédio das Transações, eximindo a ZEBRA CÂMBIO de
                quaisquer responsabilidades advindas dessas normas.
                <br />
                <br />
                <h2>Cancelamento da Transação</h2>
                <br />O CLIENTE ZEBRA poderá solicitar o cancelamento da Transação após seu
                fechamento na ZebraOnline. Nesse caso, o CLIENTE ZEBRA deverá arcar com todos os
                custos, taxas, encargos inerentes à Transação e seu cancelamento, incluindo
                eventuais encargos e custos das instituições bancárias no exterior, os quais serão
                debitados do valor a ser devolvido. O cancelamento pode depender da formalização de
                pedido nesse sentido por parte do CLIENTE ZEBRA, ou mesmo da assinatura de novo
                contrato de câmbio, nos termos da regulamentação aplicável. Nos casos de
                cancelamento de transações, poderá ser cobrada uma taxa fixa de cancelamento de
                contrato de câmbio, além da variação cambial, tributos inerentes à operação de
                devolução, descontos por parte do banco internacional e/ou encargos financeiros
                impostos pelo Bacen. Após o CLIENTE ZEBRA aprovar uma Transação na ZebraOnline, a
                ZebraOnline tomará as medidas necessárias para garantir a realização da Transação
                com base na taxa de câmbio acordada. A partir desse momento, caso o CLIENTE ZEBRA
                cancele a Transação (independentemente de já ter realizado a TED para o ZEBRA CÂMBIO
                ou não), o cancelamento da Transação poderá importar em custos decorrentes da
                variação cambial. O pedido de cancelamento, nesses casos, pressupõe a concordância
                do CLIENTE ZEBRA em arcar com os custos de cancelamento aqui referidos, os quais
                serão cobrados do CLIENTE ZEBRA ou debitados do valor a ser ressarcido ao CLIENTE
                ZEBRA, conforme o caso. Quando os valores ainda estiverem em posse do ZEBRA CÂMBIO
                ou seus intermediários, o estorno da operação poderá se dar no prazo de 2 (dois)
                dias úteis. Caso os valores já tenham sido transferidos para as contas indicadas
                pelo CLIENTE ZEBRA, a instituição bancária do destinatário poderá ou não acatar o
                pedido de devolução de recursos. A ZEBRA CÂMBIO e o ZEBRA CÂMBIO não podem obrigar a
                devolução dos valores transferidos e pagos ao CLIENTE ZEBRA ou a terceiros, seja no
                Brasil ou no exterior. Se a instituição bancária do destinatário devolver os valores
                objeto de uma Transação por acatar a solicitação de cancelamento, a ZEBRA CÂMBIO
                somente estornará os valores após os recursos terem sido identificados na conta do
                ZEBRA CÂMBIO, sujeito às deduções aqui referidas. Quando exigido pela
                regulamentação, o montante a ser reembolsado por remessas ao exterior pelo ZEBRA
                CÂMBIO será convertido para moeda nacional utilizando a taxa de câmbio aplicável no
                dia da celebração do contrato de câmbio da devolução, e o crédito em moeda nacional
                será feito pelo ZEBRA CÂMBIO na conta corrente informada pelo CLIENTE ZEBRA.
                <br />
                <br />
                <h2>Erros de sistema</h2>
                <br />
                As Transações na ZebraOnline podem sofrer erros sistêmicos, que ocorrem por
                problemas de automatização do sistema e que não envolvem qualquer interação humana.
                A ocorrência de erros sistêmicos poderá resultar, indevidamente, em pagamentos de
                Transações ao CLIENTE ZEBRA de forma duplicada ou em valor maior do que o
                contratado. O CLIENTE ZEBRA concorda que ao realizar a contratação de Transações com
                a ZEBRA CÂMBIO, e na ocorrência de um erro sistêmico que resulte em pagamentos a
                maior, o CLIENTE ZEBRA deverá realizar a devolução imediata e integra do valor, na
                moeda em que o valor foi pago a maior, seja em Reais ou qualquer outra moeda
                estrangeira, tendo em vista que a não-devolução configura crime de apropriação,
                previsto no art. 169 do Decreto-Lei 2848/40 (Código Penal). O CLIENTE ZEBRA concorda
                que, uma vez notificado (via e-mail, telefone, carta com AR, mensagem instantânea ou
                qualquer outro meio) a respeito da Pendência Financeira, o CLIENTE ZEBRA deverá
                realizar a devolução integral e imediata dos valores pagos indevidamente para a
                ZEBRA CÂMBIO, na moeda em que o valor foi pago a maior, seja em Reais ou qualquer
                outra moeda estrangeira, em até 15 (quinze dias) a contar do envio da notificação.
                Caso o CLIENTE ZEBRA não realize a devolução nesses termos, a ZEBRA CÂMBIO poderá
                realizar a quitação da Pendência Financeira de forma espontânea, através da retenção
                do valor devido de qualquer nova Transação realizada pelo CLIENTE ZEBRA. Ou seja,
                caso o CLIENTE ZEBRA com uma Pendência Financeira realize uma nova Transação, o
                valor devido à ZEBRA CÂMBIO será debitado da nova Transação, sendo pago somente o
                valor remanescente ("Retenção de Pendência Financeira”). Para fins exemplificativos:
                O CLIENTE ZEBRA recebeu o equivalente a R$1.500,00 (mil e quinhentos reais) a mais
                de uma Transação e após ser notificado do ocorrido, não realizou a quitação da
                Pendência Financeira. O CLIENTE ZEBRA, ao realizar uma nova Transação no valor
                equivalente a R$3500,00 (três mil e quinhentos reais), receberá no beneficiário
                final apenas o valor equivalente a R$2000,00 (dois mil reais), tendo em vista a
                retenção automática dos R$1500,00 (mil e quinhentos reais) que existiam de Pendência
                Financeira. A Pendência Financeira sempre será considerada em reais e o pagamento da
                mesma pelo CLIENTE ZEBRA também sempre deverá ser realizada em reais. Em caso de
                Retenção de Pendência Financeira em relação a uma Nova Transação realizada em moeda
                estrangeira, será realizada a Retenção do valor em reais considerando a cotação do
                dia da Nova Transação. Tributação no Brasil Ao aprovar uma Transação na ZebraOnline,
                será demonstrado e acrescido o valor do IOF (Imposto sobre Operações Financeiras),
                quando aplicável à operação de câmbio objeto da Transação. O CLIENTE ZEBRA sempre
                será o contribuinte do IOF de acordo com a legislação tributária vigente no Brasil.
                O ZEBRA CÂMBIO fará o recolhimento do IOF perante a Receita Federal, conforme as
                normas em vigor. A alíquota do IOF sobre operações de câmbio poderá variar
                dependendo da natureza da Transação e será definida a partir da classificação e do
                enquadramento da Transação pelo cliente zebra. O cliente zebra concorda em pagar e
                ressarcir a zebra câmbio e/ou o zebra câmbio por quaisquer cobranças de iof,
                incluindo multa e juros, outros encargos e custos de defesa, que possam ser exigidos
                pelas autoridades competentes em razão da classificação da transação e da celebração
                do contrato de câmbio com o zebra câmbio. Será informado, no recibo/comprovante da
                Transação contratada, o valor correspondente ao IOF pago pelo CLIENTE ZEBRA, e
                regularmente recolhido pelo ZEBRA CÂMBIO à Receita Federal, além de outras
                informações. Não haverá a emissão de comprovação individual de recolhimento de IOF
                conforme legislação vigente. O CLIENTE ZEBRA é o único responsável pelo cálculo e
                pagamento de qualquer tributo adicional que incida ou venha a incidir na Transação,
                seja ele tributo municipal, estadual e/ou federal ou de renda, bem como pela
                prestação de qualquer declaração exigida em conformidade com a legislação aplicável
                em decorrência de suas atividades e/ou da Transação. Na tela de finalização da
                Transação na ZebraOnline, poderá ser indicado eventual IR (Imposto de Renda) ou
                outro tributo que o CLIENTE ZEBRA deverá pagar em razão da operação de câmbio objeto
                da Transação. É de responsabilidade exclusiva do CLIENTE ZEBRA o cálculo e pagamento
                dos tributos devidos em decorrência de suas atividades, incluindo aqueles que possam
                ser exigidos em razão ou para fins de pagamentos do e para o exterior em virtude de
                transações celebradas através da ZebraOnline. Qualquer informação apresentada pela
                ZEBRA CÂMBIO na ZebraOnline com relação a tributos não deverá ser entendida pelo
                CLIENTE ZEBRA como orientação, por parte da ZEBRA CÂMBIO, sobre aspectos
                tributários, os quais deverão ser verificados e validados pelo CLIENTE ZEBRA de
                forma independente ou com a ajuda de seus assessores. O CLIENTE ZEBRA concorda em
                pagar e ressarcir a ZEBRA CÂMBIO e/ou o ZEBRA CÂMBIO por quaisquer cobranças de
                tributos devidos pelos CLIENTE ZEBRA que possam ser promovidas pelas autoridades
                competentes, incluindo multa e juros, outros encargos e custos de defesa.
                <br />
                <br />
                <h2>Práticas Vedadas</h2>
                <br />É terminantemente vedado ao CLIENTE ZEBRA, ao acessar a ZebraOnline: (i)
                praticar ou fomentar a prática de quaisquer atos ou atividades ilegais; (ii) enviar
                ou receber recursos sem comprovação de renda ou origem por meios lícitos; e (iii)
                enviar recursos para localidade diversa da qual o destinatário da Transação reside
                ou tem sede. Ao CLIENTE ZEBRA, é expressamente vedado aproveitar-se de erros
                sistêmicos, inclusive aqueles relacionados às precificações das taxas de câmbio. Nos
                casos de erro na ZebraOnline, a ZEBRA CÂMBIO informará aos CLIENTE ZEBRA, e as
                Transações feitas durante o período de erro poderão ser canceladas sem aviso prévio,
                visando preservar a integridade de todas as partes envolvidas. É proibida a coleta,
                o armazenamento e o tratamento, por parte do CLIENTE ZEBRA, de quaisquer informações
                pessoais dos demais CLIENTE ZEBRA da ZebraOnline. É terminantemente vedado o uso da
                ZebraOnline por menores de idade, salvo emancipados, desde que haja a apresentação
                de documentos comprobatórios e que tal condição seja devidamente informada e
                aprovada através da ZebraOnline. O CLIENTE ZEBRA, ao acessar a ZebraOnline, se
                obriga a não praticar atos que possam impedir a normal utilização ou que possam
                danificar, inutilizar, sobrecarregar, deteriorar ou de qualquer forma modificar a
                ZebraOnline, dispositivos informáticos de outros CLIENTE ZEBRA, da ZEBRA CÂMBIO ou
                de quaisquer terceiros, incluindo, mas não se limitando a hardwares e softwares, bem
                como documentos, arquivos e conteúdos disponibilizados e/ou armazenados nos
                referidos dispositivos. O CLIENTE ZEBRA se obriga, ainda, a não utilizar qualquer
                sistema, incluindo, mas sem se limitar, a "robôs", "spiders", "scripts" ou "offline
                readers", que acessem a ZebraOnline e venham a utilizá-la de forma desleal,
                manipuladora ou contrária ao previsto nestes Termos de Uso, na Política de
                Privacidade e nas demais regras que governam o uso da ZebraOnline e na legislação em
                vigor. É vedado ao CLIENTE ZEBRA utilizar qualquer sistema para o envio de
                requisições de acesso e utilização da ZebraOnline que supere, em um dado período, o
                que seria normalmente possível responder, levando ao impedimento de acesso,
                deteriorando ou de qualquer forma alterando a experiência de utilização dos
                conteúdos constantes na ZebraOnline. O CLIENTE ZEBRA ainda se obriga a não realizar
                práticas de "engenharia reversa", transmitir, publicar, divulgar ou criar obras
                derivadas de qualquer material a que venha a ter acesso na ZebraOnline, os quais
                deve assumir estarem protegidos por direitos de propriedade intelectual
                independentemente de qualquer orientação adicional por escrito a respeito e/ou
                quando a divulgação de tais materiais não seja autorizada pelo seu autor ou pelo
                detentor dos seus direitos de propriedade intelectual. Essa proibição não abrange os
                usos permitidos pelas limitações e exceções aos direitos de propriedade intelectual,
                no estrito sentido conferido pela legislação em vigor. Sanções A ZEBRA CÂMBIO poderá
                excluir qualquer CLIENTE ZEBRA da ZebraOnline, a seu exclusivo critério, ou ainda
                bloquear, suspender ou proibir o acesso do CLIENTE ZEBRA e/ou a realização de
                Transação pendente, inclusive com a consequente eliminação de qualquer conteúdo da
                ZebraOnline: (i) por determinação legal; (ii) em virtude de ordem judicial ou por
                determinação de autoridade competente; (iii) para evitar ou corrigir quaisquer
                elementos que, a seu exclusivo critério, possam trazer ou tenham trazido prejuízos
                ou qualquer tipo de dano à ZEBRA CÂMBIO, ao ZEBRA CÂMBIO ou a qualquer terceiro;
                (iv) quando as Transações realizadas pelo CLIENTE ZEBRA estiverem em desacordo com o
                previsto neste Termos de Uso ou em qualquer legislação aplicável; (v) quando houver
                indícios de que os CLIENTE ZEBRA possam estar agindo de má-fé na utilização da
                ZebraOnline; (vi) quando houver indícios de que os CLIENTE ZEBRA possam estar
                descumprindo a legislação e regulamentação aplicáveis, inclusive com base nos
                procedimentos de prevenção e combate à lavagem de dinheiro e ao financiamento ao
                terrorismo; (vii) na hipótese elencada no item 2.1.5 dos presentes Termos e/ou
                (viii) por solicitação do ZEBRA CÂMBIO, seja ela fundamentada em qualquer dos itens
                acima ou não.
                <br />
                <br />
                <h2>Limitação de Responsabilidade</h2>
                <br />A ZEBRA CÂMBIO envidará os melhores esforços para que a ZebraOnline seja
                plenamente acessível a todo e qualquer tempo. Todavia, não há garantia de que o
                acesso e a sua utilização se deem sem qualquer falha ou de forma ininterrupta. A
                ZEBRA CÂMBIO não poderá ser responsabilizada pelo CLIENTE ZEBRA ou por qualquer
                terceiro em função do impedimento ou alteração na forma de acesso, utilização e
                funcionalidades, ainda que temporárias, da ZebraOnline. A ZEBRA não tem obrigação de
                monitorar, fiscalizar ou controlar o acesso e a utilização que os CLIENTE ZEBRA
                fazem da ZebraOnline. A ZEBRA CÂMBIO não garante que outros CLIENTE ZEBRA venham a
                utilizar a ZebraOnline em conformidade com os termos de uso, a política de
                privacidade, demais regras que regulem o acesso e a utilização da ZebraOnline, bem
                como a legislação em vigor. A ZEBRA CÂMBIO se exime de qualquer responsabilidade
                pelos danos e prejuízos diretos e/ou indiretos de qualquer natureza que possam ser
                causados em virtude do acesso e da utilização da ZebraOnline, das informações
                inseridas por seus CLIENTE ZEBRA, incluindo danos decorrentes da falta de
                veracidade, vigência e autenticidade da informação que os CLIENTE ZEBRA fornecem
                acerca de si próprios, além daqueles decorrentes da simulação por um CLIENTE ZEBRA
                da personalidade de um terceiro. A ZEBRA CÂMBIO não é responsável pelos erros de
                preenchimento dos dados bancários por parte dos CLIENTE ZEBRA que poderão levar à
                transferência de valores para contas no exterior que não as desejadas pelo CLIENTE
                ZEBRA. O preenchimento dos campos bancários, sobretudo das contas destinatárias, é
                de única e exclusiva responsabilidade do CLIENTE ZEBRA. Operações de envio de
                valores para uma conta bancária nos países europeus, Oriente Médio, Norte da África
                e Caribe, requerem a inclusão dos dados de IBAN e/ou código SWIFT para garantir que
                a Operação seja processada corretamente, sendo de única e exclusiva responsabilidade
                do CLIENTE ZEBRA garantir que todas as informações requeridas pelo instituição
                bancária da conta destinatária sejam informadas. A ZEBRA CÂMBIO não é responsável
                pelos erros de preenchimento ou fornecimento de dados incompletos por parte dos
                CLIENTE ZEBRA. A ZEBRA CÂMBIO envidará seus melhores esforços para, dentro dos
                padrões recomendados de segurança e da legislação aplicável em vigor, garantir que
                os dados pessoais informados pelos CLIENTE ZEBRA sejam protegidos e mantidos
                confidenciais. Todavia, a ZEBRA CÂMBIO não pode garantir que a proteção dos dados e
                a segurança da ZebraOnline jamais venham a ser violadas. Nessas circunstâncias, o
                CLIENTE ZEBRA consente que a ZEBRA CÂMBIO não pode ser responsabilizada pela prática
                não autorizada de atos de terceiros que promovam interceptação, eliminação,
                alteração, modificação ou manipulação dos conteúdos da ZebraOnline e dos dados
                pessoais e demais informações e materiais disponibilizados pelos CLIENTE ZEBRA. O
                CLIENTE ZEBRA é o único responsável por qualquer informação disponibilizada na
                ZebraOnline e pelo uso que faz da mesma, respondendo integralmente por qualquer
                ofensa a direitos de terceiros que sua utilização possa causar. O CLIENTE ZEBRA
                assume integralmente a responsabilidade em caso de qualquer demanda judicial ou
                arbitral por parte de terceiros que, de qualquer modo, afete a ZEBRA CÂMBIO, seus
                diretores, empregados e colaboradores, com relação ou em decorrência de seu acesso e
                utilização da ZebraOnline em violação aos termos de uso, política de privacidade,
                regras que regulam o acesso e a utilização da ZebraOnline e a legislação em vigor.
                Caberá ao CLIENTE ZEBRA indenizar regressivamente a ZEBRA CÂMBIO caso a ZEBRA CÂMBIO
                venha a ser condenada ao pagamento de indenização ou outro pagamento de natureza
                distinta, em virtude de atos praticados pelo CLIENTE ZEBRA quando do seu acesso e
                utilização da ZebraOnline. A ZEBRA CÂMBIO não é responsável pela prestação de
                quaisquer serviços ou bens, vendidos, anunciados ou simplesmente direcionados
                através da ZebraOnline ou endereços eletrônicos de sua titularidade para sites de
                anunciantes, patrocinadores, outros CLIENTE ZEBRA ou parceiros. A ZEBRA CÂMBIO e o
                ZEBRA CÂMBIO não serão responsabilizados, sob nenhuma circunstância, por quaisquer
                perdas, danos, vícios e/ou falhas, decorrentes da transação havida entre CLIENTE
                ZEBRA e a instituição bancária destinatária dos recursos, incluindo, mas não se
                limitando, os causados pelos seguintes cenários: (i) pagamentos feitos a destinos
                indesejados ou pagamentos em valores incorretos devido à digitação de informações
                incorretas por parte do CLIENTE ZEBRA; (ii) erro ou equivoco por parte do CLIENTE
                ZEBRA por falta de entendimento ou compreensão das informações inseridas na
                ZebraOnline; e/ou (iii) atrasos, perdas, erros ou omissões resultantes da falha de
                telecomunicação ou de qualquer outro sistema de transmissão de dados e da falha
                central do sistema do computador ou qualquer parte dele para a ZebraOnline. A ZEBRA
                CÂMBIO não se responsabiliza por eventuais taxas cobradas pela instituição bancária
                do CLIENTE ZEBRA ou de terceiros no exterior, devendo quaisquer reclamações nesse
                sentido serem encaminhadas diretamente aos canais competentes para avaliação por
                parte de tal instituição. É dever exclusivo do CLIENTE ZEBRA informar, declarar e
                recolher os impostos e tributos incidentes sobre a natureza da transação ou dela
                decorrentes ou relacionados que porventura venham a incidir ou se tornar devidos a
                partir da remessa de recursos do ou para o exterior (remetidos ou internalizados),
                bem como atender todas as obrigações acessórias e os deveres instrumentais exigidos
                pela administração fazendária. A ZEBRA CÂMBIO não se responsabilizará ou poderá ser
                responsabilizada, em hipótese alguma, pelos impostos ou tributos que o CLIENTE
                ZEBRA, por desconhecimento ou qualquer outro motivo, deixe de recolher à
                administração fazendária em decorrência da operação realizada de envio ou
                recebimento de valores através da ZebraOnline. A ZEBRA CÂMBIO, o ZEBRA CÂMBIO e
                qualquer intermediário por ela contratados não serão considerados em mora ou
                inadimplentes no cumprimento de quaisquer de suas obrigações previstas nestes termos
                de uso se o motivo de seu descumprimento decorrer de caso fortuito ou de força
                maior, incluindo falhas decorrentes de interrupções na prestação de serviços por
                terceiros.
                <br />
                <br />
                <h2>Propriedade Intelectual</h2>
                <br />O CLIENTE ZEBRA está ciente de que as marcas, nomes, logotipos, bem como todo
                e qualquer conteúdo, desenho, arte ou layout presente na ZebraOnline e decorrentes
                do uso dos Serviços, são de propriedade exclusiva da ZEBRA CÂMBIO ou tem seu uso
                pela ZEBRA CÂMBIO autorizado por seu proprietário. Para a utilização de quaisquer
                destas marcas, nomes, sinais distintivos e outros, é necessária a obtenção de prévia
                e expressa autorização de seus titulares de direito. Em caso de violação, a ZEBRA
                CÂMBIO se reserva o direito de determinar a imediata remoção do conteúdo, sem
                prejuízo de outras sanções cíveis e penais estabelecidas na legislação pertinente.
                São vedados quaisquer atos ou contribuições tendentes à modificação das
                características, ampliação, alteração ou incorporação em quaisquer outros programas
                dos sistemas da ZebraOnline, inclusive a prática de "engenharia reversa". Toda e
                qualquer forma de reprodução da ZebraOnline ou dos Serviços, total ou parcial,
                permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer
                modalidades, formas ou títulos é expressamente vedada. O conteúdo disponibilizado na
                ZebraOnline caracteriza somente licença não exclusiva, não transferível e não
                sublicenciável, para acessar e fazer uso dos Serviços da ZebraOnline, não implicando
                qualquer cessão ou transferência de titularidade de direitos da ZEBRA CÂMBIO ao
                CLIENTE ZEBRA. Nem estes Termos de Uso e nem a utilização dos Serviços, transfere ou
                concede ao CLIENTE ZEBRA quaisquer direitos, exceto pela licença limitada concedida
                acima.
                <br />
                <br />
                <h2>Ficha Cadastral e Contrato de Câmbio</h2>
                <br />
                Os CLIENTE ZEBRA reconhecem a veracidade, autenticidade, integridade, validade e
                eficácia destes Termos de Uso e dos demais instrumentos e documentos aprovados ou
                celebrados através da ZebraOnline para prestação dos Serviços, realização do
                Cadastro e formalização de Transações, incluindo contratos de câmbio ("Documentos"),
                aprovados em formato eletrônico e/ou por meio de certificados eletrônicos, ainda que
                sejam certificados eletrônicos não emitidos pela ICP-Brasil. Os CLIENTE ZEBRA
                expressamente anuem, autorizam, aceitam e reconhecem como válida qualquer forma de
                comprovação do consentimento e/ou de autoria das partes aos Documentos, inclusive
                por meio de suas respectivas assinaturas aos Documentos por meio de certificados
                eletrônicos, quando for esse o caso, sendo certo que qualquer certificado será
                suficiente para a veracidade, autenticidade, integridade, validade e eficácia dos
                Documentos e seus termos, bem como a respectiva vinculação das partes aos seus
                termos. Os CLIENTE ZEBRA expressamente anuem e autorizam que, eventualmente, as suas
                assinaturas não precisem necessariamente ser apostas na mesma página de assinaturas
                dos Documentos que sejam assinados pelas partes e que a troca de páginas de
                assinaturas, assinadas e escaneadas em formato eletrônico, como, por exemplo,
                ".pdf", é tão válida e produz os mesmos efeitos que a assinatura original de cada um
                aposta nos Documentos.
                <br />
                <br />
                <h2>Vigência</h2>
                <br />
                Ao dar de acordo, o CLIENTE ZEBRA declara ter lido previamente todo o conteúdo deste
                Termos de Uso, assim como da Política de Privacidade e demais normas que governam a
                ZebraOnline, e ter compreendido integralmente seu conteúdo, ficando ciente que ao
                aderir, todos seus direitos e obrigações ficarão a ele vinculados, tornando-se tais
                regras e condições de utilização indispensáveis para contratação dos Serviços
                solicitados pelo CLIENTE ZEBRA e contratação de Transações. O presente termo
                vigorará por prazo indeterminado, podendo ser rescindido pela ZEBRA CÂMBIO a
                qualquer momento, a seu exclusivo critério, independentemente do pagamento de
                qualquer compensação ou indenização, seja a que título for. A ZEBRA CÂMBIO poderá, a
                qualquer tempo, promover modificações nos presentes Termos de Uso, assim como na
                Política de Privacidade e nas demais normas que governem a ZebraOnline. A
                continuação do uso dos Serviços após a alteração dos Termos de Uso serve de
                manifestação inequívoca de consentimento e, portanto, vincula o CLIENTE ZEBRA quanto
                aos termos alterados.
                <br />
                <br />
                <h2>Geral</h2>
                <br />
                Os Documentos e demais informações e documentos colocados à disposição na
                ZebraOnline serão compartilhadas entre ZEBRA CÂMBIO para fins da prestação dos
                Serviços e consecução das Transações, com o que os CLIENTE ZEBRA concordam e
                expressamente autorizam. Estes Termos de Uso foram atualizados em 01 de Dezembro de
                2023 e são a versão válida e eficaz dos termos e condições que regulam a
                ZebraOnline, a prestação dos Serviços e a realização de Transações. Essa versão rege
                todas as relações passadas e presentes entre os CLIENTE ZEBRA da ZebraOnline e a
                ZEBRA CÂMBIO, incluindo os Agentes de Câmbio, naquilo que aplicável, respeitados os
                direitos adquiridos, atos jurídicos perfeitos e coisas julgadas. O CLIENTE ZEBRA
                deverá sempre ler atentamente os presentes Termos de Uso e não poderá escusar-se
                deles alegando ignorância sobre seus termos, inclusive quanto a eventuais
                modificações. O não exercício pela ZEBRA CÂMBIO de quaisquer direitos ou faculdades
                que lhes sejam conferidos pelos Termos de Uso, pela Política de Privacidade, pelas
                regras que regulam o acesso e a utilização da ZebraOnline ou pela legislação em
                vigor, bem como a eventual tolerância contra infrações aos dispositivos dos
                instrumentos legais e contratuais acima mencionados (incluindo quaisquer
                Documentos), não importará em renúncia aos direitos ou novação, podendo seus
                direitos serem exercidos a qualquer tempo. A aceitação destes Termos de Uso, bem
                como da Política de Privacidade e das regras que regulam o acesso e utilização da
                ZebraOnline não importa na criação de qualquer vínculo trabalhista, societário, de
                parceria ou associativo entre a ZEBRA CÂMBIO e o CLIENTE ZEBRA. A Política de
                Privacidade e demais regras que regulam o acesso e a utilização da ZebraOnline são
                indissociáveis destes Termos de Uso. Em caso de conflito entre disposições, os
                Termos de Uso prevalecerão. Na hipótese de o CLIENTE ZEBRA contestar uma Transação
                realizada pelo ZEBRA CÂMBIO, a ZEBRA CÂMBIO confirmará se ela foi realizada em
                conformidade com as instruções dadas pelo CLIENTE ZEBRA à ZEBRA CÂMBIO.
                <br />
                <br />
                <h2>Canal de Atendimento</h2>
                <br />A ZEBRA CÂMBIO possui um canal de atendimento ao CLIENTE ZEBRA, disponível 5
                (cinco) dias por semana, durante o horário comercial, por meio do e-mail XXXX , chat
                online, telefone e WhatsApp, conforme indicados na ZebraOnline.
                <br />
                <br />
                <h2>Legislação e Escolha de Foro</h2>
                <br />O presente Termos de Uso será regido pela legislação brasileira. Fica eleito
                exclusivamente o Foro Central da Comarca de Jundiaí para dirimir a resolução de
                quaisquer conflitos entre as partes na interpretação e execução destes Termos de
                Uso, da Política de Privacidade e das regras que regulam o acesso e utilização da
                ZebraOnline.
                <br />
                <br />
                <p className="highlight">
                    Ao clicar em 'Eu aceito', você está declarando concordar com todas as políticas
                    de uso da nossa ferramenta de câmbio online.
                </p>
                <br />
                <div className="button">
                    <Button name="Eu aceito" onClick={acceptPolicy}></Button>
                </div>
            </div>
        </div>
    );
};

export default Policy;
