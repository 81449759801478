import React, { useEffect, useState, useContext, useCallback } from "react";
import ExchangeBox from "../../components/ExchangeBox/ExchangeBox";
import Modalities from "../../assets/json/modalities";
import exchangeValues from "utils/ExchangeValues";
import DownArrow from "assets/icons/down-arrow.svg";
import CoinQuote from "components/CoinQuote/CoinQuote";
import { RequireAuth } from "contexts/RequireAuth";
import axios from "axios";
import { AuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const BANKTAX = 0.004;
const API = "https://0bivx59r0m.execute-api.sa-east-1.amazonaws.com/v1/coin/searchcoin";

const Exchange = () => {
    const [coins, setCoins] = useState();
    const [howToOption, setHowToOption] = useState("enviar");
    const [modality, setModality] = useState();
    const [step, setStep] = useState("modality");
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const [quote, setQuote] = useState({
        user: "",
        document: "",
        price: 0,
        brlQuote: 0,
        coinQuote: 0,
        flow: "",
        coin: "USD",
        modality: "",
        modalityId: -1,
        way: "",
        transaction: "",
        spread: 0.012,
        spreadValue: 0,
        administrationFee: 25,
        iof: -1,
        ir: 0,
        modalityName: "Selecione uma modalidade",
        iofValue: 0,
        irValue: 0,
        vet: 0,
        files: [],
    });

    useEffect(() => {
        if (auth.user) {
            setQuote((prev) => ({
                ...prev,
                user: auth.user.name,
                document: auth.user.document,
            }));
        }
    }, [auth.user]);

    // Lista as moedas
    const fetchCoins = () => {
        if (auth.user) {
            axios
                .get(API, {
                    headers: {
                        "x-access-token": auth.user.apiToken,
                    },
                })
                .then((response) => {
                    const novaLista = response.data.map((objeto) => ({
                        ...objeto,
                        bid: objeto.bid * (1 + BANKTAX),
                    }));

                    setCoins(novaLista);
                })
                .catch((err) => {
                    console.log("Ops! Ocorreu um erro: " + err);
                    auth.logout();
                    navigate("/login");
                });
        }
    };

    // Atualiza a cotação nos inputs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateQuotation = useCallback(() => {
        if (quote.brlQuote > 149 && quote.way === "receber") {
            exchangeValues(quote.brlQuote, quote, "nacionalValue");
        } else if (quote.brlQuote > 149 && quote.way === "enviar") {
            exchangeValues(quote.coinQuote, quote, "internacionalValue");
        }
    });

    // Atualiza o IOF ao alterar a modalidade
    useEffect(() => {
        const modality = Modalities.find((obj) => obj.id === quote.modalityId);

        if (modality) {
            setModality(modality);
            setQuote((prev) => ({
                ...prev,
                iof: modality.iof,
                ir: modality.ir,
                modality: modality.cod,
                modalityName: modality.nome,
                way: modality.transacao,
            }));

            updateQuotation();

            if (quote.iof === -1) {
                setStep("quotation");
            }
        }
    }, [quote.iof, quote.modalityId, updateQuotation]);

    // Atualiza a cotação das moedas
    useEffect(() => {
        if (coins) {
            const selectedCoin = coins.find((obj) => obj.from === quote.coin);
            if (selectedCoin) {
                // const newPrice = selectedCoin.bid * (1 + BANKTAX);
                setQuote((prev) => ({
                    ...prev,
                    price: selectedCoin.bid.toFixed(4),
                }));
            }
            updateQuotation();
            exchangeValues(quote.coinQuote, quote, coins, false);
        }
    }, [quote.coin, coins, step, updateQuotation, quote]);

    // Atualiza o câmbio a cada X segundos
    useEffect(() => {
        fetchCoins();
        const intervalId = setInterval(fetchCoins, 5000);

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user]);

    return (
        <RequireAuth>
            <div className="exchange-page">
                <p className="top-message">
                    A Zebra é aprovada pelo Banco Central, certificada pela ABRACAM e parte de uma
                    das maiores empresas de comércio no exterior do Brasil.
                    <br />
                    <br />
                    <a href="#how-to">
                        Saiba como pagar e receber <img src={DownArrow} alt="Ver mais" />
                    </a>
                </p>
                <div className="how-to" id="how-to">
                    <div className="coins-quote">
                        <CoinQuote coins={coins} coin={"USD"} />
                        <CoinQuote coins={coins} coin={"EUR"} />
                        <CoinQuote coins={coins} coin={"GBP"} />
                        <CoinQuote coins={coins} coin={"CHF"} />
                        <CoinQuote coins={coins} coin={"MXN"} />
                    </div>
                    <div className="menu">
                        <h3
                            onClick={() => setHowToOption("enviar")}
                            style={
                                howToOption === "enviar"
                                    ? {
                                          borderBottom: "3px solid #0080ff",
                                      }
                                    : {
                                          borderBottom: "0px",
                                      }
                            }
                        >
                            COMO ENVIAR
                        </h3>
                        <h3
                            onClick={() => setHowToOption("receber")}
                            style={
                                howToOption === "receber"
                                    ? {
                                          borderBottom: "3px solid #0080ff",
                                      }
                                    : {
                                          borderBottom: "0px",
                                      }
                            }
                        >
                            COMO RECEBER
                        </h3>
                    </div>

                    {howToOption === "enviar" && (
                        <div>
                            <section className="steps">
                                <div
                                    className="step"
                                    style={{
                                        color: step === "modality" ? "#0080ff" : "",
                                    }}
                                >
                                    <p className="number">1</p>
                                    <p className="description">Selecione uma modalidade.</p>
                                </div>
                                <p className="modality-description">{modality?.descricao}</p>
                                <div
                                    className="step"
                                    style={{
                                        color:
                                            step === "quotation" || step === "confirm"
                                                ? "#0080ff"
                                                : "",
                                    }}
                                >
                                    <p className="number">2</p>
                                    <p className="description">Feche a cotação.</p>
                                </div>
                                {modality ? (
                                    <div>
                                        <div
                                            className="step"
                                            style={{
                                                color: step === "document" ? "#0080ff" : "",
                                            }}
                                        >
                                            <p className="number">3</p>
                                            <p className="description">
                                                Anexe os documentos necessários citados abaixo.
                                            </p>
                                        </div>
                                        <div className="details">
                                            {modality.documentos.length > 0 ? (
                                                modality.documentos.map((doc, index) => (
                                                    <div key={index}>
                                                        <p>
                                                            <span>Anexo {index + 1}:</span> {doc}
                                                        </p>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>
                                                    Esta modalidade não exige o envio de documentos.
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="step">
                                        <p className="number">3</p>
                                        <p className="description">
                                            Anexe os documentos necessários da modalidade
                                            selecionada.
                                        </p>
                                    </div>
                                )}
                                <div
                                    className="step"
                                    style={{
                                        color: step === "sent" ? "#0080ff" : "",
                                    }}
                                >
                                    <p className="number">4</p>
                                    <p className="description">
                                        Aguarde o boleto e efetue o pagamento via PIX ou TED.
                                    </p>
                                </div>
                                <div className="step">
                                    <p className="number">5</p>
                                    <p className="description">
                                        O dinheiro chega no destino em até 1 dia útil.
                                    </p>
                                </div>
                            </section>
                        </div>
                    )}
                    {howToOption === "receber" && (
                        <div>
                            <p className="highight">
                                Atenção: Esta opção está disponível apenas ao contatar diretamente
                                os nossos traders.
                            </p>
                            <section className="steps">
                                <div className="step">
                                    <p className="number">1</p>
                                    <p className="description">
                                        Envie nosso canal bancário para o seu cliente.
                                    </p>
                                </div>
                                <div className="step">
                                    <p className="number">2</p>
                                    <p className="description">
                                        Aguarde a nossa comunicação de que a transferência foi
                                        recebida.
                                    </p>
                                </div>
                                <div
                                    className="step"
                                    style={{
                                        color: step === "modality" ? "#0080ff" : "",
                                    }}
                                >
                                    <p className="number">3</p>
                                    <p className="description">Selecione uma modalidade.</p>
                                </div>
                                <p className="modality-description">{modality?.descricao}</p>
                                <div
                                    className="step"
                                    style={{
                                        color:
                                            step === "quotation" || step === "confirm"
                                                ? "#0080ff"
                                                : "",
                                    }}
                                >
                                    <p className="number">4</p>
                                    <p className="description">Feche a cotação.</p>
                                </div>
                                {modality ? (
                                    <div>
                                        <div
                                            className="step"
                                            style={{
                                                color: step === "document" ? "#0080ff" : "",
                                            }}
                                        >
                                            <p className="number">5</p>
                                            <p className="description">
                                                Envie o invoice com os dados da conta do exterior.
                                            </p>
                                        </div>
                                        <div className="details">
                                            {step === "document" && (
                                                <p>
                                                    <span>O que é invoice?</span> <br />
                                                    Um "Invoice" é um documento que descreve o que
                                                    foi comprado ou vendido em uma transação
                                                    comercial. Ele contém informações sobre os
                                                    produtos ou serviços, os valores e as condições
                                                    de pagamento.
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="step">
                                        <p className="number">3</p>
                                        <p className="description">
                                            Anexe os documentos necessários da modalidade
                                            selecionada.
                                        </p>
                                    </div>
                                )}
                                <div
                                    className="step"
                                    style={{
                                        color: step === "sent" ? "#0080ff" : "",
                                    }}
                                >
                                    <p className="number">6</p>
                                    <p className="description">
                                        O dinheiro chega no destino em até 1 dia útil.
                                    </p>
                                </div>
                            </section>
                        </div>
                    )}
                </div>
                <div className="exchange-area">
                    <ExchangeBox
                        className="exchange-box"
                        coins={coins}
                        quote={quote}
                        setQuote={setQuote}
                        modality={modality}
                        step={step}
                        setStep={setStep}
                    />
                </div>
            </div>
        </RequireAuth>
    );
};

export default Exchange;
