import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

// Componente de Página de Login
const Login = () => {
    // Estados e variáveis de estado inicial
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    let [borderColor, setBorderColor] = useState("#cdcdcd");
    let navigate = useNavigate();
    const auth = useContext(AuthContext);

    // Função de autenticação do usuário
    const authUser = async (event) => {
        event.preventDefault();

        if (email && password) {
            const login = await auth.signin(email, password);

            if (login) {
                setBorderColor("#cdcdcd");
                navigate("/cotacao");
            } else {
                setBorderColor("#ff8578");
                document.getElementById("autMessage").innerHTML = "E-mail ou senha incorretos.";
            }
        }
    };

    // Retorna a estrutura da página de login
    return (
        <div className="login-page">
            <form className="login-box" onSubmit={authUser}>
                <h1>Bem-vindo à Zebra</h1>
                <Input
                    type="text"
                    placeholder="Digite seu email"
                    title="E-MAIL"
                    borderColor={borderColor}
                    value={(email) => setEmail(email)}
                />
                <div>
                    <Input
                        type="password"
                        placeholder="Digite sua senha"
                        title="SENHA"
                        borderColor={borderColor}
                        value={(password) => setPassword(password)}
                    />
                    <div className="small-text">
                        <p id="autMessage" className="auth-message"></p>
                        {/* <Link href="mailto:contato@zebracambio.com.br" className="forgot-password">
                            Esqueci minha senha
                        </Link> */}
                    </div>
                </div>
                <div>
                    <Button name="Entrar" backgroundColor="#00ffc3" fontColor="black" />
                    {/* <p className="register">
                        Ainda não é cadastrado?{" "}
                        <a href="https://zebracambio.com.br/cadastre-se/">Cadastre-se</a>
                    </p> */}
                </div>
            </form>
        </div>
    );
};

export default Login; // Exporta o componente Login
