import React, { useState } from "react";
import DownArrow from "assets/icons/down-arrow.svg";
import UpArrow from "assets/icons/up-arrow.svg";
import CoinsOptions from "../CoinsOptions/CoinsOptions";
import exchangeValues from "utils/ExchangeValues";

const InputValue = ({
    coins,
    coin,
    quote,
    dropdown,
    inputAlert,
    alert,
    submitButton,
    disabled,
}) => {
    const [arrowIcon, setArrow] = useState(DownArrow);
    const [displayStyle, setDisplayStyle] = useState("none");
    const brlIcon = "https://assets.zebra.app.br/webp/coins/BRL.webp";
    const coinIcon = `https://assets.zebra.app.br/webp/coins/${quote.coin}.webp`;

    const openOptions = () => {
        if (dropdown !== false) {
            setArrow(arrowIcon === DownArrow ? UpArrow : DownArrow);
            setDisplayStyle(displayStyle === "none" ? "" : "none");
        }
    };

    const setCoin = (c) => {
        openOptions();
        coin(c);
    };

    const setValue = (e) => {
        if (e.target.value.length === 1 && isNaN(e.target.value)) {
            e.target.value = "";
        } else {
            exchangeValues(e.target.value, quote, coins, true);
        }
    };

    const setAlert = () => {
        quote.modality === "" ? alert(true) : alert(false);
    };

    return (
        <div>
            <div
                className="input-value"
                onClick={setAlert}
                style={{ borderColor: inputAlert ? "#f33f21" : "" }}
            >
                <div
                    className="coin-options"
                    id={dropdown !== false && "inputValue"}
                    onClick={openOptions}
                >
                    <img
                        src={dropdown === false ? brlIcon : coinIcon}
                        alt="País"
                        className="coin-icon"
                    />
                    <p className="coin-name">{dropdown === false ? "BRL" : quote.coin}</p>
                    {dropdown !== false && (
                        <img src={arrowIcon} alt="Mais opções de moeda" className="arrow" />
                    )}
                </div>

                {quote.modality !== "" && (
                    <input
                        type="text"
                        placeholder="0,00"
                        maxLength="17"
                        autocomplete="off"
                        id={dropdown === false ? "nacionalValue" : "internacionalValue"}
                        onChange={(e) => setValue(e)}
                        disabled={disabled || submitButton}
                    />
                )}
            </div>

            <CoinsOptions display={displayStyle} coins={coins} setCoin={setCoin} />
        </div>
    );
};

export default InputValue;
