const Checkbox = ({ options, flow, alert, setAlert }) => {
    const selectOption = (option) => {
        flow(option.substring(0, 2));
        setAlert(false);
    };

    return (
        <div className="checkbox">
            {options.map((item, index) => (
                <div>
                    <input
                        type="radio"
                        name="options"
                        id={item}
                        onClick={() => selectOption(item)}
                    />
                    <label
                        for={item}
                        style={{
                            borderColor: alert === true ? "#f33f21" : "",
                        }}
                    >
                        {item}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default Checkbox;
