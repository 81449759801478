import { BrowserRouter, Route, Routes } from "react-router-dom";
import ExchangePage from "./pages/Exchange/ExchangePage.jsx";
import LoginPage from "./pages/Login/LoginPage.jsx";
import Navbar from "./components/Navbar/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";

function AppRoutes() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/cotacao" element={<ExchangePage />} />
                <Route path="*" element={<LoginPage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default AppRoutes;
