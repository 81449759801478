const Input = (props) => {
    const setValue = (event) => {
        props.value(event.target.value);
    };

    return (
        <div className="input">
            {props.title !== "" && <h4>{props.title}</h4>}
            <input
                type={props.type}
                placeholder={props.placeholder}
                style={{ borderColor: props.borderColor }}
                onChange={setValue}
            />
        </div>
    );
};

export default Input;
