const AttachButton = ({
    quote,
    name,
    cod,
    backgroundColor,
    attachAlert,
    height,
    icon,
    documentName,
}) => {
    const fileInserted = (event) => {
        const label = document.getElementById(cod);
        label.style.backgroundColor = "#0080ff";
        label.style.color = "#ffffff";

        // Verifica se há algum arquivo
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            // Obtém a extensão do arquivo
            const extensao = file.name.split(".").pop();

            // Novo nome desejado com a extensão
            const novoNome = `${documentName}.${extensao}`;

            // Cria uma nova instância de File com o nome modificado
            const novoArquivo = new File([file], novoNome, { type: file.type });

            // Adiciona o novo arquivo à array quote.files
            quote.files.push(novoArquivo);
        }
    };

    return (
        <label
            htmlFor={name}
            className="attach-button"
            id={cod}
            style={{
                backgroundColor: backgroundColor,
                color: attachAlert ? "#f33f21" : "",
                height: height,
            }}
        >
            <img src={icon} alt={name} style={{ height: "95%" }} />
            {name}
            <input type="file" id={name} onChange={fileInserted} hidden />
        </label>
    );
};

export default AttachButton;
