import formatToBrl from "utils/FormatToBrl";

const CoinQuote = ({ coins, coin }) => {
    const coinObj = coins?.find((obj) => obj.from === coin);
    return (
        <div className="coin-quote">
            <img
                src={`https://assets.zebra.app.br/webp/coins/${coin}.webp`}
                alt={coin}
                className="icon"
            />
            <h6 className="name">{coin}</h6>
            <p className="price">R$ {coinObj?.bid.toFixed(4)}</p>
        </div>
    );
};

export default CoinQuote;
