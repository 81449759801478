import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import Login from "pages/Login/LoginPage";
import Policy from "components/Policy/policy";

export const RequireAuth = ({ children }) => {
    const auth = useContext(AuthContext);
    const policy = localStorage.getItem("policy");

    if (!auth.user) {
        return <Login />;
    }

    if (!policy) {
        return <Policy />;
    }

    return children;
};
