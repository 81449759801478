import logo from "assets/images/zebra-logo-horizontal-branco.png";
import logoutIcon from "assets/icons/logout.svg";
import menuIcon from "assets/icons/menu.svg";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

const Navbar = () => {
    const [menu, setMenu] = useState(false);
    const auth = useContext(AuthContext);

    const toggleMenu = () => {
        setMenu(!menu);
    };

    return (
        <nav className="navbar">
            <img src={logo} alt="Logo dLink Zebra" className="logo" />

            <img src={menuIcon} alt="Menu" className="menu" onClick={toggleMenu} />
            <div
                className="menu-options"
                style={{
                    display:
                        !menu && window.matchMedia("(max-width: 500px)").matches ? "none" : "flex",
                }}
            >
                {auth.user ? (
                    <>
                        <Link
                            to="https://wa.me/5511998951892"
                            className="link"
                            onClick={toggleMenu}
                            target="_blank"
                        >
                            Contato
                        </Link>
                        <Link to="/cotacao" className="link" onClick={toggleMenu}>
                            Cotação
                        </Link>
                        <div className="menu-user">
                            <p className="user-name">Olá, {auth.user.name}</p>
                            <Link to="/" className="btn-logout">
                                <img src={logoutIcon} alt="Botão de Sair" onClick={auth.logout} />
                            </Link>
                        </div>
                    </>
                ) : (
                    <>
                        <Link to="https://zebracambio.com.br/" className="link">
                            Conheça a Zebra
                        </Link>
                        <Link to="/" className="btn-logout" onClick={toggleMenu}>
                            <p>Entrar</p>
                        </Link>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
