import InterrogationIcon from "assets/icons/interrogation.svg";

const Tooltip = ({ text, backgroundColor, color, height }) => {
    return (
        <>
            <div className="tooltip">
                <img
                    src={InterrogationIcon}
                    alt="O que é Fluxo de Transação"
                    className="icon"
                    style={{ height: height }}
                />
                <div
                    className="message-box"
                    style={{ color: color, backgroundColor: backgroundColor }}
                >
                    <p>{text}</p>
                </div>
            </div>
        </>
    );
};

export default Tooltip;
